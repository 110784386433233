import {
	StylovyzeDialog,
	decoratedAttributes,
	useGlobalization,
	useIsFeatureEnabled,
} from '@innovyze/stylovyze';
import {
	DialogProps,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	selectRehabTreesIsCreating,
	selectRehabTreesList,
	selectRehabTreesNameError,
	selectAssetFilters,
} from '@Selectors';
import {
	addRehabTree,
	setNewTreeName,
	setRehabTreeNameError,
} from '@Actions/index';

interface DropDownOption {
	value: string | number;
	name: string;
	disabled: boolean;
}

interface Props {
	open: boolean;
	onClose: () => void;
}

export const NewRehab = ({ open, onClose }: Props) => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const history = useHistory();

	const existingTrees = selectRehabTreesList();
	const assetFilters = useSelector(selectAssetFilters);
	const [newName, setNewName] = useState('');
	const [newType, setNewType] = useState('');
	const [newSystemType, setNewSystemType] = useState('');
	const [inspectionStandard, setInspectionStandard] = useState('');
	const [disableStandardTypes, setDisableStandardTypes] = useState(true);
	const [assetFilterId, setAssetFilterId] = useState('');
	const sytemTypeList: DropDownOption[] = [
		{ name: t('Sanitary Sewer'), disabled: false, value: 0 },
		{ name: t('Water Distribution'), disabled: false, value: 1 },
	];

	const assetTypeList: DropDownOption[][] = [
		[
			{ name: t('Pipe'), disabled: false, value: 'pipe' },
			{ name: t('Manhole'), disabled: true, value: 'manhole' },
		],
		[{ name: t('Pipe'), disabled: false, value: 'pipe' }],
	];

	const inspectionStandardList: DropDownOption[] = [
		{ name: t('PACP'), disabled: false, value: 'PACP' },
		{
			name: t('WSA05'),
			disabled: false,
			value: 'WSA05',
		},
	];

	const nameError = selectRehabTreesNameError();
	const isCreating = selectRehabTreesIsCreating();

	const handleCancelNewModel = () => {
		onClose();
	};
	const shouldShowAssetsFilter = !!useIsFeatureEnabled(
		'info-360-assets-filter-rehab',
	);

	useEffect(() => {
		setNewName('');
		dispatch(setRehabTreeNameError(t('Name is required')));
		setNewSystemType('');
		setNewType('');
		setInspectionStandard('');
		setDisableStandardTypes(true);
	}, [open]);
	const handleCreateNewModel = () => {
		if (
			nameError.length === 0 &&
			newSystemType.length !== 0 &&
			newType.length !== 0 &&
			(disableStandardTypes || inspectionStandard.length !== 0)
		) {
			dispatch(
				setNewTreeName({
					name: newName.trim(),
					systemType:
						sytemTypeList.find(
							s => s.value === Number.parseInt(newSystemType),
						)?.name || 'Sanitary Sewer',
					assetType: newType,
					assetFilterId: assetFilterId || undefined,
					treeNodes: [],
					inspectionStandard: inspectionStandard,
				}),
			);
			dispatch(
				addRehabTree({
					history: history,
					assetFilterId: assetFilterId || undefined,
				}),
			);
		}
	};

	const handlelNameChangeValue = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		let error = '';
		if (event.target && event.target.value !== '') {
			setNewName(event.target.value);
			const duplicateName = existingTrees.filter(
				tree =>
					tree.name.toUpperCase().trim() ===
					event.target.value.toUpperCase().trim(),
			);

			if (duplicateName.length > 0) {
				error = t('Enter a unique name');
			} else {
				const rexp = new RegExp('^[a-zA-Z0-9 ]+$');
				const test = rexp.test(event.target.value);
				if (!test) {
					error = t('Only alphanumeric characters are allowed');
				} else {
					const rexp2 = new RegExp('^.{1,50}$');
					const test2 = rexp2.test(event.target.value);
					if (!test2) {
						error = t(
							'Name cannot contain more than 50 characters',
						);
					}
				}
			}
		} else {
			setNewName('');
			error = t('Name is required');
		}
		dispatch(setRehabTreeNameError(error));
	};

	const handleSystemTypeSelectChange = (event: SelectChangeEvent<string>) => {
		const target = event?.target as HTMLInputElement;
		if (target != undefined) {
			setNewSystemType(target.value.toString());
			setDisableStandardTypes(target.value.toString() !== '0');
		} else {
			setNewSystemType('');
			setDisableStandardTypes(true);
		}

		setNewType('');
		setInspectionStandard('');
	};

	const handleAssetTypeSelectChange = (
		event: SelectChangeEvent<typeof newType>,
	) => {
		const target = event?.target as HTMLInputElement;
		setNewType(target.value);
	};

	const handleInspectionStandardSelectChange = (
		event: SelectChangeEvent<string>,
	) => {
		const target = event?.target as HTMLInputElement;
		if (target != undefined) {
			setInspectionStandard(target.value as string);
		} else {
			setInspectionStandard('PACP');
		}
	};

	const handleAssetFilterSelectChange = (event: SelectChangeEvent<any>) => {
		const target = event?.target as HTMLInputElement;
		setAssetFilterId(target.value);
	};

	// This stops the dialog closing when clicked on the background
	const onCloseDialog = () => void {};

	const dialogProps: DialogProps = {
		open: open,
		maxWidth: 'md',
		onClose: onCloseDialog,
	};

	const isCreateButtonDisabled = () =>
		isCreating ||
		nameError.length !== 0 ||
		newSystemType.length === 0 ||
		newType.length === 0 ||
		(!disableStandardTypes && inspectionStandard.length === 0);

	const adpAttrs = decoratedAttributes({
		systemtype: newSystemType,
		inspectionstandard: inspectionStandard,
		name: newName,
		type: newType,
		disablestandardtypes: disableStandardTypes.toString(),
	});

	return (
		<StylovyzeDialog
			dialogProps={dialogProps}
			data-cy="new-dialog"
			title={t('New Decision Tree')}
			open={open}
			cancelText={t('Cancel')}
			cancelButtonProps={{
				disabled: isCreating,
				dataCy: 'create-cancel',
			}}
			onCancel={handleCancelNewModel}
			confirmText={t('Create Decision Tree')}
			confirmButtonProps={{
				disabled: isCreateButtonDisabled(),
				dataCy: 'create-confirm',
				'adp-click': 'true',
				id: 'create-decision-tree',
				type: 'button',
				name: 'Create Decision Tree',
				...adpAttrs,
			}}
			onConfirm={handleCreateNewModel}>
			<Stack style={{ width: '400px' }}>
				<FormControl variant="outlined" margin="normal" fullWidth>
					<TextField
						fullWidth
						error={nameError.length !== 0}
						required
						name="name"
						label={t('Enter Name')}
						variant="outlined"
						onChange={handlelNameChangeValue}
						helperText={nameError.length !== 0 ? nameError : ' '}
					/>
				</FormControl>
				<FormControl variant="outlined" margin="normal" fullWidth>
					<InputLabel
						id="system-type-select-label"
						variant="outlined"
						error={newSystemType.length === 0}>
						{t('System Type')}
					</InputLabel>
					<Select
						labelId="system-type-select-label"
						data-cy="systemTypeField"
						name="systemType"
						error={newSystemType.length === 0}
						onChange={handleSystemTypeSelectChange}
						label={t('System Type')}
						value={newSystemType}>
						{sytemTypeList.map((systemType, index) => {
							return (
								<MenuItem value={systemType.value} key={index}>
									{systemType.name}
								</MenuItem>
							);
						})}
					</Select>
					<FormHelperText error>
						{newSystemType.length === 0
							? t('Select a System Type')
							: ' '}
					</FormHelperText>
				</FormControl>
				<FormControl variant="outlined" margin="normal" fullWidth>
					<InputLabel
						variant="outlined"
						error={
							newSystemType.length !== 0 && newType.length === 0
						}>
						{t('Asset Type')}
					</InputLabel>
					<Select
						data-cy="assetTypeField"
						name="assetType"
						value={newType}
						error={
							newSystemType.length !== 0 && newType.length === 0
						}
						onChange={handleAssetTypeSelectChange}
						label={t('Asset Type')}>
						{newSystemType.length !== 0 &&
							assetTypeList[parseInt(newSystemType)].map(
								(assetTypeObj, index) => {
									return (
										<MenuItem
											value={assetTypeObj.value}
											key={index}
											disabled={assetTypeObj.disabled}>
											{assetTypeObj.name}
										</MenuItem>
									);
								},
							)}
					</Select>
					<FormHelperText error>
						{newSystemType.length !== 0 && newType.length === 0
							? t('Select an asset type')
							: ' '}
					</FormHelperText>
				</FormControl>
				{shouldShowAssetsFilter && (
					<FormControl variant="outlined" margin="dense" fullWidth>
						<InputLabel>{t('Asset Filter')}</InputLabel>
						<Select
							name="assetFilter"
							onChange={handleAssetFilterSelectChange}
							label={t('Asset Filter')}>
							{assetFilters &&
								assetFilters.map(f => (
									<MenuItem value={f._id} key={f._id}>
										{f.name}
									</MenuItem>
								))}
						</Select>
						<FormHelperText> </FormHelperText>
					</FormControl>
				)}
				<FormControl variant="outlined" margin="normal" fullWidth>
					<InputLabel
						id="inspection-standard-select-label"
						variant="outlined"
						error={
							!disableStandardTypes &&
							inspectionStandard.length === 0
						}>
						{t('Inspection Standard')}
					</InputLabel>
					<Select
						data-cy="inspectionStandardField"
						disabled={disableStandardTypes}
						name="inspectionStandard"
						error={
							!disableStandardTypes &&
							inspectionStandard.length === 0
						}
						onChange={handleInspectionStandardSelectChange}
						label={t('Inspection Standard')}
						value={inspectionStandard}>
						{inspectionStandardList.map(
							(inspectionTypeObj, index) => {
								return (
									<MenuItem
										value={inspectionTypeObj.value}
										key={index}
										disabled={inspectionTypeObj.disabled}>
										{inspectionTypeObj.name}
									</MenuItem>
								);
							},
						)}
					</Select>
					<FormHelperText error>
						{!disableStandardTypes &&
						inspectionStandard.length === 0
							? t('Select an Inspection Standard')
							: ' '}
					</FormHelperText>
				</FormControl>
			</Stack>
		</StylovyzeDialog>
	);
};
