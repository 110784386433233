import { CostUnit, RehabAction, RehabResults } from '@Types';
import { selectUserContext } from '@Selectors';
import {
	Drawer,
	closeDrawer,
	useUnits,
	HTMLInputElementExtended,
	HTMLTextAreaElementExtended,
	CurrencyField,
} from '@innovyze/stylovyze';
import {
	Backdrop,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from './RehabResultDrawer.styles';
import {
	selectIsCostLoading,
	selectRehabActions,
	selectRehabCost,
} from '@Selectors/Rehab.selectors';
import { editAction, getRehabCost } from '@Actions/Rehab.actions';
import Autocomplete from '@mui/material/Autocomplete';
import { useGlobalization } from '@Translations';

interface Props {
	treeId: string;
	assetType: string;
	viewerAccess: boolean;
	rehabResult: RehabResults | undefined | null;
}

export const RehabResultDrawer = ({
	treeId,
	assetType,
	viewerAccess,
	rehabResult,
}: Props) => {
	const { t } = useGlobalization();
	const classes = useStyles();
	const dispatch = useDispatch();

	const { system } = useUnits();

	const getUnitDescription = (
		unit: string,
		length: string,
		count: string,
		isMeter: boolean,
	) => {
		if (unit == 'length') {
			return isMeter
				? t('/m (length) [{{value}}]', {
						value: length != '' ? (+length).toFixed(2) : '--',
				  })
				: t('/ft (length) [{{value}}]', {
						value:
							length != ''
								? (+length * 3.28084).toFixed(2)
								: '--',
				  });
		} else {
			return t('EACH [{{value}}]', { value: count == '' ? '--' : count });
		}
	};

	const getRehabAction = (rehabResult: RehabResults | null | undefined) => {
		return (
			rehabResult?.rehabAction ?? {
				_id: '',
				actionId: '',
				treeId: treeId,
				unitCost: 0,
				costUnit: 'USD', //"USD" or "GBP", now is not used
				unit: CostUnit.each,
				settings: {
					enabled: true,
					defects: [],
				},
				notes: rehabResult?.notes ?? '',
			}
		);
	};

	const getCostCalculation = (rehabAction: RehabAction) => {
		if (rehabAction == null || rehabAction == undefined) return '';
		if (system === 'Metric') {
			return t('Unit Cost [{{value}} * {{unit}}]', {
				value: rehabAction.unitCost.toFixed(2),
				unit: getUnitDescription(rehabAction.unit, '', '', true),
			});
		} else {
			return t('Unit Cost [{{value}} * {{unit}}]', {
				value: (rehabAction.unitCost / 3.28084).toFixed(2),
				unit: getUnitDescription(rehabAction.unit, '', '', false),
			});
		}
	};

	const [rehabAction, setRehabAction] = useState<RehabAction>(
		getRehabAction(rehabResult),
	);

	const [totalCost, setTotalCost] = useState<number>(
		rehabResult?.totalCost ?? 0,
	);

	const [costCalculation, setCostCalculation] = useState<string>(
		rehabResult?.costCalculation ?? '',
	);

	const [notes, setNotes] = useState<string>(rehabResult?.notes ?? '');
	const [isOverrideCost, setIsOverrideCost] = useState<boolean>(false);

	const [locked, setLocked] = useState<string>(rehabResult?.locked ?? '0');

	const [isDirty, setIsDirty] = useState<boolean>(false);

	const rehabActions = selectRehabActions();
	const rehabCost = selectRehabCost();
	const isCostLoading = selectIsCostLoading();

	useEffect(() => {
		if (!isOverrideCost) {
			if (!rehabCost) {
				setCostCalculation(getCostCalculation(rehabAction));
			} else {
				const costCalculation =
					system == 'Metric'
						? rehabCost.COST_CALCULATION_METER
						: rehabCost.COST_CALCULATION_FT;
				setTotalCost(rehabCost.TOTAL_COST ? rehabCost.TOTAL_COST : 0);
				setCostCalculation(
					costCalculation && costCalculation != ''
						? costCalculation
						: '',
				);
			}
		}
	}, [rehabCost, isOverrideCost]);

	const updateValue = (
		event: ChangeEvent<
			| HTMLInputElement
			| HTMLInputElementExtended
			| HTMLTextAreaElementExtended
		>,
		sField: 'TotalCost' | 'Notes',
	) => {
		// Dirty flag set for any changes for the moment.
		setIsDirty(true);
		const sValue = event.target.value;
		switch (sField) {
			case 'TotalCost':
				{
					const dValue = Number(sValue);
					setTotalCost(dValue);
				}
				break;
			case 'Notes': {
				setNotes(sValue);
				break;
			}
			default:
				break;
		}
	};

	const userContext = selectUserContext();

	const handleOverrideCostSwitch = (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean,
	) => {
		setIsDirty(true);
		setIsOverrideCost(checked);
		if (!checked) {
			dispatch(
				getRehabCost({
					treeId: treeId,
					actionId: rehabAction._id,
					assetId: rehabResult?.assetId ? rehabResult?.assetId : '',
					assetType: assetType,
				}),
			);
		} else {
			setCostCalculation(
				t('Cost Override by: {{username}}', {
					username: userContext.name,
				}),
			);
		}
	};

	const handleLockActionSwitch = () => {
		setIsDirty(true);
		setLocked(locked == '1' ? '0' : '1');
	};

	const rehabActionInputs = (
		<Grid xs={12} item>
			<FormControl
				variant="outlined"
				margin="normal"
				fullWidth
				disabled={viewerAccess}>
				<Autocomplete
					className={classes.defectRoot}
					id="rehabAction"
					freeSolo
					options={rehabActions?.map(
						rehabAction => rehabAction.actionId,
					)}
					getOptionLabel={option => option}
					defaultValue={rehabAction?.actionId}
					renderInput={params => (
						<TextField
							{...params}
							variant="outlined"
							label={t('Rehab Action')}
						/>
					)}
					onChange={(event, newValue) => {
						setIsDirty(true);
						const foundAction = rehabActions.find(action => {
							return action.actionId == newValue;
						});
						if (foundAction) {
							if (!isOverrideCost) {
								dispatch(
									getRehabCost({
										treeId: treeId,
										actionId: foundAction._id,
										assetId: rehabResult?.assetId
											? rehabResult?.assetId
											: '',
										assetType: assetType,
									}),
								);
							}
							setRehabAction(foundAction);
						} else {
							rehabAction.actionId = newValue ?? '';
						}
					}}
				/>
			</FormControl>
		</Grid>
	);

	const overrideCostSwitchInputs = (
		<Grid xs={5} item style={{ paddingLeft: '30px' }}>
			<FormControlLabel
				control={
					<Switch
						size="small"
						checked={isOverrideCost}
						onChange={handleOverrideCostSwitch}
					/>
				}
				label={t('Override Cost')}
			/>
		</Grid>
	);

	const costCalculationLabel = (
		<Grid xs={7} item>
			<Grid container spacing={0}>
				<Grid xs={12} item>
					<Typography
						style={{
							fontSize: '12px',
							color: '#4A6067',
						}}>
						{t('Cost Calculation')}
					</Typography>
				</Grid>
				<Grid xs={12} item>
					<Typography
						style={{
							fontSize: '14px',
							color: '#272833',
						}}>
						{costCalculation
							.replace('Unit Cost', t('Unit Cost'))
							.replace('length', t('length'))
							.replace('Cost Override by', t('Cost Override by'))}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);

	const totalCostInputs = (
		<Grid xs={12} item>
			<CurrencyField
				fullWidth
				name="UnitCost"
				onChange={(
					// eslint-disable-next-line prettier/prettier
					event: ChangeEvent<
						HTMLInputElement | HTMLTextAreaElementExtended
					>,
				) => {
					updateValue(event, 'TotalCost');
				}}
				defaultValue={
					rehabResult && rehabResult.totalCost
						? rehabResult.totalCost >= 0
							? rehabResult.totalCost.toFixed(2).toString()
							: '0'
						: '0'
				}
				disabled={!isOverrideCost}
				label={t('Total Cost')}
				variant="outlined"
				min={0}
				step={'1'}
			/>
		</Grid>
	);

	const notesInputs = (
		<Grid xs={12} item>
			<TextField
				fullWidth
				name="Notes"
				defaultValue={notes}
				value={notes}
				onChange={(
					// eslint-disable-next-line prettier/prettier
					event: ChangeEvent<HTMLInputElement>,
				) => {
					setIsDirty(true);
					updateValue(event, 'Notes');
				}}
				label={t('Notes')}
				variant="outlined"
			/>
		</Grid>
	);

	const lockActionSwitchInputs = (
		<Grid xs={12} item style={{ paddingLeft: '30px' }}>
			<FormControlLabel
				control={
					<Switch
						size="small"
						checked={locked == '1'}
						onChange={handleLockActionSwitch}
					/>
				}
				label={t('Lock Action')}
			/>
		</Grid>
	);

	const params = () => {
		return (
			<Grid container spacing={2}>
				{rehabActionInputs}
				{overrideCostSwitchInputs}
				{costCalculationLabel}
				{totalCostInputs}
				{notesInputs}
				{lockActionSwitchInputs}
			</Grid>
		);
	};

	const handleSave = () => {
		const modifiedRehabResult: RehabResults = {
			treeId: treeId,
			assetId: rehabResult?.assetId ?? '',
			rehabAction: { ...rehabAction, notes: notes },
			costCalculation: costCalculation,
			totalCost: isOverrideCost ? totalCost : -1,
			locked: locked,
			draftRehabAction: rehabResult?.draftRehabAction,
			actionNodeId: rehabResult?.actionNodeId,
		};
		dispatch(editAction(modifiedRehabResult));
	};

	return (
		<Drawer
			action={{
				label: t('Save'),
				onClick: function noRefCheck() {
					handleSave();
					dispatch(closeDrawer());
				},
				disabled: !isDirty,
			}}
			drawerId="editRehabResultDrawer"
			closeOnClickAway={false}
			onCancel={() => dispatch(closeDrawer())}
			onOpen={() => {
				setIsDirty(false);
				if (rehabAction) {
					setRehabAction(getRehabAction(rehabResult));
					setIsOverrideCost(
						rehabResult &&
							rehabResult.costCalculation != null &&
							rehabResult.costCalculation != undefined &&
							rehabResult.costCalculation.includes('Override')
							? true
							: false,
					);
					setTotalCost(
						rehabResult?.totalCost != null &&
							rehabResult?.totalCost != undefined
							? rehabResult?.totalCost
							: 0,
					);
					setCostCalculation(rehabResult?.costCalculation ?? '');
					setLocked(rehabResult?.locked ?? '0');
					setNotes(
						rehabResult?.notes ??
							rehabResult?.rehabAction?.notes ??
							'',
					);
				}
			}}
			title={t('Edit')}>
			<>
				<Backdrop open={isCostLoading} className={classes.drawerLoader}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<form
					className={classes.formRoot}
					noValidate
					autoComplete="off">
					{params()}
				</form>
			</>
		</Drawer>
	);
};
