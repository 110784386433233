import { Environments } from '@innovyze/lib_get_service';

const config: any = {
	[Environments.LOCAL]: {
		amRehab: 'https://saas-rehab-management.info360-dev.com/dev',
		saasAmRehab: 'https://saas-am-rehab.wlc360a-c-uw2.autodesk.com/dev',
		amInspection: 'https://saas-inspection-management.info360-dev.com/dev',
	},
	[Environments.DEV]: {
		amRehab: 'https://saas-rehab-management.info360-dev.com/dev',
		saasAmRehab: 'https://saas-am-rehab.wlc360a-c-uw2.autodesk.com/dev',
		amInspection: 'https://saas-inspection-management.info360-dev.com/dev',
	},
};

export const apiEnv =
	(localStorage.getItem('apiEnv') as keyof typeof Environments) || undefined;

export default config;
