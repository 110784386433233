import { BinarySearchTree } from './BTS';
import {
	ActionObj,
	NodeSchema,
	NODE_TYPES,
	ReactFlowDecisionTree,
	ReactFlowSchema,
	RehabAction,
	RehabDecisionTree,
	TreeNodeSchema,
} from '@Types';
import { RECORD_TYPE } from './choiceListMapping';
import { Dictionary } from 'lodash';
import { has, groupBy, some, isEqual } from 'lodash-es';
import { ReactNode } from 'react';
import { Edge, Elements, isEdge, isNode, Node } from 'react-flow-renderer';
import * as uuid from 'uuid';

const getAllEdges = (elements: ReactFlowSchema[]): ReactFlowSchema[] => {
	return elements.filter(element => element.source);
};

export const getAllNodes = (elements: ReactFlowSchema[]): ReactFlowSchema[] => {
	return elements.filter(element => element.position);
};

const createRehabTree = (
	BSTree: BinarySearchTree,
	treeNodesMap: Dictionary<ReactFlowSchema[]>,
	treeEdgesMap: Dictionary<ReactFlowSchema[]>,
	sourceId: string,
) => {
	if (BSTree.treeNodes == null) {
		const data: TreeNodeSchema = { ...treeNodesMap[sourceId][0].data };
		data.position = treeNodesMap[sourceId][0].position;
		data.type = treeNodesMap[sourceId][0].type;
		BSTree.insert(BSTree.treeNodes, sourceId, null, data);
	}

	// TODO: Replace with JS
	if (has(treeEdgesMap, sourceId)) {
		treeEdgesMap[sourceId].forEach(edge => {
			if (edge.target && edge.source) {
				const data: TreeNodeSchema = {
					...treeNodesMap[edge.target][0].data,
				};

				data.position = treeNodesMap[edge.target][0].position;
				data.type = treeNodesMap[edge.target][0].type;
				data.connectorCondition = edge.label?.split(' ')[0];

				BSTree.insert(BSTree.treeNodes, edge.target, edge.source, data);
			}
		});

		if (treeEdgesMap[sourceId][0] && treeEdgesMap[sourceId][0].target) {
			createRehabTree(
				BSTree,
				treeNodesMap,
				treeEdgesMap,
				treeEdgesMap[sourceId][0].target as string,
			);
		}
		if (treeEdgesMap[sourceId][1] && treeEdgesMap[sourceId][1].target) {
			createRehabTree(
				BSTree,
				treeNodesMap,
				treeEdgesMap,
				treeEdgesMap[sourceId][1].target as string,
			);
		}
	}
};

export const convertReactFlowObjectToTree = (
	elements: ReactFlowSchema[],
	rootId: string,
) => {
	if (elements.length === 1) {
		const BSTree = new BinarySearchTree();
		if (BSTree.treeNodes == null) {
			const data: TreeNodeSchema = { ...elements[0].data };
			data.position = elements[0].position;
			data.type = elements[0].type;
			const key = elements[0].id ? elements[0].id : '';
			BSTree.insert(BSTree.treeNodes, key, null, data);
		}
		return BSTree;
	}

	const allEdges = getAllEdges(elements);

	allEdges.sort((a, b) => +(a.source ?? 0) - +(b.source ?? 0));

	const treeNodesMap = groupBy(getAllNodes(elements), 'id');

	const treeEdgesMap = groupBy(allEdges, 'source');
	const BSTree = new BinarySearchTree();
	createRehabTree(BSTree, treeNodesMap, treeEdgesMap, rootId);

	return BSTree;
};

// convert tree to ReactFlow object

function printTopToBottomPath(
	curr: NodeSchema,
	parent: any,
): ReactFlowSchema[] {
	const stk = [];
	const connections: ReactFlowSchema[] = [];
	const connectionsWithEl: ReactFlowSchema[] = [];

	while (curr != null) {
		const reactFlowObj: ReactFlowSchema = {
			id: curr.id,
			parentId: curr.parentId ? curr.parentId : null,
			data: {
				label: curr.title ? curr.title : '',
				nodeData: {
					title: curr.title,
					filterCondition: curr.filterCondition,
					rules: curr.rules ? curr.rules : [],
					actionDefectCodes: curr.actionDefectCodes
						? curr.actionDefectCodes
						: [],
					assetsCount: curr.assetsCount,
					actionId: curr.actionId,
					treeId: curr.treeId,
					unitCost: curr.unitCost,
					costUnit: curr.costUnit,
					unit: curr.unit,
					notes: curr.notes,
				},
			},
			connectorCondition: curr.connectorCondition,
			type: curr.type,
			position: {
				x: curr.position ? curr.position.x : 0,
				y: curr.position ? curr.position?.y : 0,
			},
		};

		connections.push(reactFlowObj);
		stk.push(curr);
		curr = parent.get(curr);
	}

	while (stk.length != 0) {
		curr = stk[stk.length - 1];
		stk.pop();
	}
	if (connections && connections.length > 0) {
		connections
			.reverse()
			.forEach((connection: ReactFlowSchema, index: number) => {
				connectionsWithEl.push(connection);
				if (index > 0) {
					let connectionLabel = connection.connectorCondition;
					if (
						connection.data?.nodeData.assetsCount?.currentNode ||
						connection.data?.nodeData.assetsCount?.currentNode === 0
					) {
						connectionLabel =
							connectionLabel +
							' (' +
							connection.data?.nodeData.assetsCount?.currentNode +
							')';
					}
					const connectionObj = {
						id: `e${connection.parentId}-${connection.id}`,
						source: connection.parentId,
						target: connection.id,
						label: connectionLabel,
					};
					connectionsWithEl.push(connectionObj);
				}
			});
	}

	return connectionsWithEl;
}

export function printRootToLeaf(root: NodeSchema): ReactFlowSchema[] {
	const reactFlowArr: ReactFlowSchema[] = [];
	// Corner Case
	if (root == null) return [];

	// Create an empty stack and push root to it
	const nodeStack = [];
	nodeStack.push(root);

	const parent = new Map();

	// parent of root is NULL
	parent.set(root, null);

	while (nodeStack.length != 0) {
		// pop the top item from stack
		const current: NodeSchema = nodeStack[nodeStack.length - 1];
		nodeStack.pop();

		if (current.left == null && current.right == null) {
			const x = printTopToBottomPath(current, parent);
			x.forEach((ele: ReactFlowSchema) => {
				// TODO: Replace with JS
				if (
					!some(reactFlowArr, reactFlowEle =>
						isEqual(reactFlowEle, ele),
					)
				) {
					reactFlowArr.push(ele);
				}
			});
		}

		if (current.right != null) {
			parent.set(current.right, current);
			nodeStack.push(current.right);
		}
		if (current.left != null) {
			parent.set(current.left, current);
			nodeStack.push(current.left);
		}
	}
	return reactFlowArr;
}

export function convertTreeToReactFlowObject(
	treeNodes: NodeSchema,
): ReactFlowSchema[] {
	const newTreeNodes = { ...treeNodes };
	let reactFlow: ReactFlowSchema[] = [];
	if (treeNodes) reactFlow = printRootToLeaf(newTreeNodes);

	return reactFlow;
}

// draft Node Functionality

export const getMainTreeNodesIds = (allNodes: any, startNodeId: string) => {
	const allEdges = getAllEdges(allNodes as any);
	allEdges.sort((a, b) => +(a.source ?? 0) - +(b.source ?? 0));
	const treeEdgesMap = groupBy(allEdges, 'source');

	//check values
	if (!startNodeId) return;
	const mainTreeId = [];
	const currentTargets = [];
	const rootNodeId =
		treeEdgesMap[startNodeId] && treeEdgesMap[startNodeId][0]
			? treeEdgesMap[startNodeId][0].target
			: null;
	if (rootNodeId) {
		mainTreeId.push(rootNodeId);
		currentTargets.push(rootNodeId);
		while (currentTargets.length !== 0) {
			const targetNodeId: any = currentTargets.shift();
			const firstChildNodeId: any = treeEdgesMap[targetNodeId]
				? treeEdgesMap[targetNodeId][0].target
				: null;
			const secondChildNodeId: any =
				treeEdgesMap[targetNodeId] && treeEdgesMap[targetNodeId][1]
					? treeEdgesMap[targetNodeId][1].target
					: null;
			if (firstChildNodeId) {
				currentTargets.push(firstChildNodeId);
				mainTreeId.push(firstChildNodeId);
			}
			if (secondChildNodeId) {
				currentTargets.push(secondChildNodeId);
				mainTreeId.push(secondChildNodeId);
			}

			//if (currentTargets.length === 0) break;
		}
	} else return;

	return mainTreeId;
};

export const extractDraftNodes = (allNodes: any, mainTreeIds: string[]) => {
	if (allNodes.length === 0) return;

	const draftNodes: any = [];
	const mainNodes: any = [];
	allNodes.forEach((node: any) => {
		if (
			mainTreeIds.includes(node.id) ||
			(node.source && mainTreeIds.includes(node.source))
		) {
			mainNodes.push(node);
		} else {
			draftNodes.push(node);
		}
	});

	return { mainNodes, draftNodes };
};

export const getStartNodeId = (allNodes: any) => {
	if (allNodes.length === 0) return;

	const startNode = allNodes.find(
		(node: any) =>
			node.data &&
			node.data.label &&
			node.type &&
			node.data.label.toUpperCase() === 'START' &&
			node.type.toUpperCase() === 'INPUT',
	);

	if (startNode) return startNode.id;

	return null;
};

export const isNonTreeNodeToTreeNode = (
	sourceId: string | null,
	targetId: string | null,
	allNodes: any,
	startNodeId: string,
) => {
	const mainTreeIds = getMainTreeNodesIds(allNodes, startNodeId);
	if (!mainTreeIds) return false;
	return mainTreeIds.includes(targetId) && !mainTreeIds.includes(sourceId);
};

export const getActionsData = (root: NodeSchema, actionObjsArr: any[]) => {
	if (root == null) return;

	if (
		root.left == null &&
		root.right == null &&
		root.type &&
		root.type === NODE_TYPES.action
	) {
		const actionObj: ActionObj = {
			nodeId: root.id as string,
			actionId: root.actionId ? root.actionId : '',
			actionName: root.title as string,
			parentId: root.parentId ? (root.parentId as string) : '',
			connectorCondition: root.connectorCondition
				? root.connectorCondition
				: '',
			unitCost: root.unitCost ? root.unitCost : 0,
			costUnit: root.costUnit ? root.costUnit : '',
			unit: root.unit ? root.unit : '',
			notes: root.notes ? root.notes : '',
		};
		actionObjsArr.push(actionObj);
		return;
	}

	if (root.left != null) getActionsData(root.left, actionObjsArr);

	if (root.right != null) getActionsData(root.right, actionObjsArr);
};

export const compareCostNotesValues = (
	action: RehabAction,
	treeActionData: ActionObj,
): boolean => {
	if (
		action.costUnit != treeActionData.costUnit ||
		action.unitCost != treeActionData.unitCost ||
		action.unit != treeActionData.unit ||
		action.notes != treeActionData.notes
	)
		return true;
	return false;
};

export const extractMainTreeAndCreateTreeObject = (
	tree: ReactFlowDecisionTree,
	startNodeId: string,
): RehabDecisionTree => {
	const mainTreeIds = getMainTreeNodesIds(tree.nodes, startNodeId);

	const allTreeNodes = mainTreeIds
		? extractDraftNodes(tree.nodes, mainTreeIds as any)
		: extractDraftNodes(tree.nodes, [] as any);

	let nodes: any = null;
	if (allTreeNodes?.mainNodes.length > 0) {
		let rootId = '';
		tree.nodes.forEach(node => {
			if (node.source && node.source === startNodeId && node.target) {
				rootId = node.target as string;
			}
		});

		nodes = convertReactFlowObjectToTree(allTreeNodes?.mainNodes, rootId);
	}

	const rehabTree: RehabDecisionTree = {
		name: tree.name,
		thumbnail: '',
		treeNodes: nodes ? nodes.treeNodes : {},
		draftNodes: allTreeNodes?.draftNodes ? allTreeNodes?.draftNodes : [],
	};
	return rehabTree;
};
/**
 *
 * @param treeNodes
 * @param action
 *
 * check only the main tree actions if the cost or method to calculating the cost or notes change
 */
export const checkIfActionCostNotesChange = (
	treeNodes: NodeSchema,
	actions: RehabAction[],
): boolean => {
	//change actions to map
	if (actions.length === 0 || !treeNodes) return false;

	let updatedCostActionHappened = false;

	const actionsData: ActionObj[] = [];
	getActionsData(treeNodes, actionsData);
	const actionsMap = groupBy(actions, '_id');

	for (const actionData of actionsData) {
		// TODO: Replace with JS
		if (actionData.actionId && has(actionsMap, actionData.actionId)) {
			const rehabAction = actionsMap[actionData.actionId][0];
			const actionIsUpdated = compareCostNotesValues(
				rehabAction,
				actionData,
			);
			if (actionIsUpdated) {
				updatedCostActionHappened = true;
				break;
			}
		}
	}
	return updatedCostActionHappened;
};

// update the tree if cost changed

export const updateTreeActionsWithUpdatedCostNotes = (
	root: NodeSchema,
	actionsMap: { [x: string]: any[] },
) => {
	if (root == null) return;

	if (
		root.left == null &&
		root.right == null &&
		root.type &&
		root.type === NODE_TYPES.action
	) {
		// TODO: Replace with JS
		if (root.actionId && has(actionsMap, root.actionId)) {
			const rehabActionData = actionsMap[root.actionId][0];
			root.costUnit = rehabActionData.costUnit;
			root.unitCost = rehabActionData.unitCost;
			root.unit = rehabActionData.unit;
			root.notes = rehabActionData.notes;
		}
		return;
	}

	if (root.left != null)
		updateTreeActionsWithUpdatedCostNotes(root.left, actionsMap);

	if (root.right != null)
		updateTreeActionsWithUpdatedCostNotes(root.right, actionsMap);
};

export const getMissingMultiRiskModelError = (
	treeNodes: ReactFlowSchema[],
	riskModels: string[],
): { errorMessage: string }[] => {
	if (treeNodes.length == 0) {
		return [];
	}
	const errorMessages = [];
	for (let i = 0; i < treeNodes.length; i++) {
		const node = treeNodes[i];
		if (node.type == NODE_TYPES.query) {
			if (
				node.data?.nodeData.rules &&
				node.data?.nodeData.rules.length > 0
			) {
				const rules = node.data?.nodeData.rules;
				for (let j = 0; j < rules.length; j++) {
					const rule = rules[j];
					if (rule.recordType == RECORD_TYPE.RISK) {
						if (!riskModels.includes(rule.config as string)) {
							errorMessages.push({
								errorMessage: `unpublished or deleted risk model in query node - ${
									node.label ? node.label : node.data.label
								}`,
							});
							continue;
						}
						if (
							rule.groupedRules &&
							rule.groupedRules?.length > 0
						) {
							const groupRules = rule.groupedRules;
							for (let j = 0; j < groupRules.length; j++) {
								const groupedRule = groupRules[i];
								if (
									!riskModels.includes(
										groupedRule.config as string,
									)
								) {
									errorMessages.push({
										errorMessage: `unpublished or deleted risk model in query node - ${
											node.label
												? node.label
												: node.data.label
										}`,
									});
									continue;
								}
							}
						}
					}
				}
			}
		}
	}

	return errorMessages;
};

export enum TREE_STATES {
	IN_PROGRESS = 'In Progress',
	COMPLETE = 'Complete',
	FAILED = 'Failed',
}

export const getAssetCountFromLabel = (label: string | ReactNode): number => {
	if (typeof label !== 'string') return 0;
	const regex = /\d+/;
	const match = label.match(regex);
	return match ? parseInt(match[0]) : 0;
};

export const getSourceTargetId = (edge: Edge): string => {
	const targetId = edge.target.includes('_clone_')
		? edge.target.split('_clone_')[0]
		: edge.target;
	const sourceId = edge.source.includes('_clone_')
		? edge.source.split('_clone_')[0]
		: edge.source;
	return `${sourceId}_${targetId}`;
};

//sum the identical edges
export const getEdgeMergedLabel = (
	edge: Edge,
	countBySourceTargetMap: { [key: string]: number },
): string | ReactNode => {
	let newLabel = edge.label;
	if (edge.label && /\(\d+\)/.test(edge.label.toString())) {
		newLabel = `${edge.label
			?.toString()
			.replace(/\(\d+\)/, '')}(${countBySourceTargetMap[
			getSourceTargetId(edge)
		].toString()})`;
	}
	return newLabel;
};

export const restoreTree = (
	nodesAndEdges: (Node<any> | Edge<any>)[],
): (Node<any> | Edge<any>)[] => {
	const edges: Edge[] = getEdges(nodesAndEdges);
	const countBySourceTargetMap: { [key: string]: number } = {};

	edges.forEach(edge => {
		const sourceTargetId = getSourceTargetId(edge);
		if (!countBySourceTargetMap[sourceTargetId]) {
			countBySourceTargetMap[sourceTargetId] = 0;
		}
		countBySourceTargetMap[sourceTargetId] += getAssetCountFromLabel(
			edge.label,
		);
	});
	return nodesAndEdges
		.map((node: Node | Edge) => {
			if (node.id.includes('_clone_')) {
				if (
					isEdge(node) &&
					!node.source.includes('_clone_') &&
					node.target.includes('_clone_')
				) {
					return {
						...node,
						id: `e${node.source}-${
							node.target.split('_clone_')[0]
						}`,
						target: node.target.split('_clone_')[0],
						label: getEdgeMergedLabel(node, countBySourceTargetMap),
					};
				}
			}
			if (isEdge(node) && !node.source.includes('idxStart')) {
				return {
					...node,
					label: getEdgeMergedLabel(node, countBySourceTargetMap),
				};
			}
			return node;
		})
		.filter((node: Node | Edge) => !node.id.includes('_clone_'));
};

export const getNodes = (elements: Elements): Node[] => {
	return elements.filter(element => isNode(element)) as Node[];
};

export const getEdges = (elements: Elements): Edge[] => {
	return elements.filter(element => isEdge(element)) as Edge[];
};

export const buildNodeParentsMap = (
	edges: Edge[],
): { [key: string]: string[] } => {
	const nodeParentsMap: { [key: string]: string[] } = {};
	edges?.forEach(edge => {
		if (!nodeParentsMap[edge.target]) {
			nodeParentsMap[edge.target] = [];
		}
		nodeParentsMap[edge.target].push(edge.source);
	});
	return nodeParentsMap;
};

export const cloneNodes = (
	nodes: Node[],
	currentEdges: Edge[],
	clonedEdges: Edge[],
	nodeParentsMap: { [key: string]: string[] },
): Node[] => {
	const clonedNodes: Node[] = [];
	nodes?.forEach(node => {
		if (nodeParentsMap[node.id] && nodeParentsMap[node.id].length > 1) {
			for (let i = 0; i < nodeParentsMap[node.id].length - 1; i++) {
				const parentId = nodeParentsMap[node.id][i];
				const newNode = cloneNode(node);
				clonedNodes.push(newNode);
				const oldEdge = currentEdges.find(
					edge => edge.source == parentId && edge.target == node.id,
				);
				if (oldEdge) {
					clonedEdges.push({
						...oldEdge,
						id: `e${parentId}-${newNode.id}`,
						target: newNode.id,
					});
				}
			}
		}
	});
	return clonedNodes;
};

export const cloneNode = (node: Node): Node => {
	let clonedNode = {} as Node;
	const uniqueId = uuid.v4().replace(/-/g, '_');
	if (node.type == NODE_TYPES.query) {
		const newId = `${node.id}_clone_${uniqueId}`;
		const newLabel = `${node.data.label}_clone_${uniqueId}`;
		clonedNode = {
			id: newId,
			type: NODE_TYPES.query,
			position: {
				x: node.position.x + 50,
				y: node.position.y + 50,
			},
			data: {
				...node.data,
				id: newId,
				label: newLabel,
				title: newLabel,
				nodeData: {
					...node.data.nodeData,
					title: newLabel,
					assetsCount: {},
				},
			},
		};
	} else {
		const newId = `${node.id}_clone_${uniqueId}`;
		clonedNode = {
			id: newId,
			type: NODE_TYPES.action,
			position: {
				x: node.position.x + 50,
				y: node.position.y + 50,
			},
			data: {
				...node.data,
				id: newId,
				nodeData: {
					...node.data.nodeData,
					assetsCount: {},
				},
			},
		};
	}
	return clonedNode;
};

export const copySubtree = (
	nodeId: string,
	newParentId: string,
	currentEdges: Edge[],
	clonedEdges: Edge[],
	nodes: Node[],
	clonedNodes: Node[],
) => {
	const childEdges = currentEdges.filter(edge => edge.source == nodeId);
	if (!childEdges || childEdges.length == 0) {
		return;
	}
	childEdges.forEach(edge => {
		const nodeToClone = nodes.find(n => n.id == edge.target);
		if (!nodeToClone) {
			return;
		}
		const newChildNode = cloneNode(nodeToClone);
		clonedNodes.push(newChildNode);
		clonedEdges.push({
			...edge,
			id: `e${newParentId}-${newChildNode.id}`,
			source: newParentId,
			target: newChildNode.id,
		});
		copySubtree(
			edge.target.split('_clone_')[0],
			newChildNode.id,
			currentEdges,
			clonedEdges,
			nodes,
			clonedNodes,
		);
	});
};
