import { AssetsCount, CostUnit, Position, Rules, TreeNodeSchema } from '@Types';

export class TreeNode {
	public id: string | number;
	public parentId: string | number | null;
	public left: TreeNode | null;
	public right: TreeNode | null;
	public position?: Position;
	public title?: string;
	public connectorCondition?: string;
	public type?: string;
	public filterCondition?: string;
	public rules?: Rules[];
	public actionDefectCodes?: string[];
	public actionId?: string;
	public treeId?: string;
	public unitCost?: number;
	public costUnit?: string;
	public unit?: CostUnit;
	public assetsCount?: AssetsCount;
	public notes?: string;

	constructor(
		data: TreeNodeSchema,
		id: string | number,
		parentId: string | number | null,
	) {
		this.id = id;
		this.parentId = parentId;
		this.position = data.position;
		this.title = data.nodeData?.title;
		this.type = data.type;
		this.rules = data.nodeData?.rules;
		this.actionDefectCodes = data.nodeData?.actionDefectCodes;
		this.actionId = data.nodeData?.actionId;
		this.treeId = data.nodeData?.treeId;
		this.unitCost = data.nodeData?.unitCost;
		this.costUnit = data.nodeData?.costUnit;
		this.unit = data.nodeData?.unit;
		this.connectorCondition = data.connectorCondition;
		this.assetsCount = data.nodeData?.assetsCount;
		this.notes = data.nodeData?.notes;
		this.left = null;
		this.right = null;
	}
}

export class BinarySearchTree {
	public treeNodes: TreeNode | null;
	constructor(treeNodes?: TreeNode) {
		this.treeNodes = treeNodes || null;
	}

	public insert(
		node: TreeNode | null = this.treeNodes,
		id: string | number,
		parentId: string | number | null,
		value: TreeNodeSchema,
	): TreeNode {
		if (node === null) {
			const root = new TreeNode(value, id, parentId);
			this.treeNodes = root;
			return root;
		} else {
			if (node.id == parentId) {
				if (!node.left) {
					node.left = new TreeNode(value, id, parentId);

					return node;
				}
				if (!node.right) {
					node.right = new TreeNode(value, id, parentId);
					return node;
				}
			}
			if (node.id != parentId && node.left) {
				node.left = this.insert(node.left, id, parentId, value);
			}
			if (node.id != parentId && node.right) {
				node.right = this.insert(node.right, id, parentId, value);
			}
			return node;
		}
	}
}
