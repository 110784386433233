import rehabService from '@Apis/rehab.api';
import { RehabQueryChoiceList } from '@Types/rehab.types';

export const getRehabRulesChoiceListApi = (
	systemType: string,
	assetType: string,
	recordType: string,
	inspectionStandard?: string,
	treeId?: string,
) => {
	const address = `/rehabTree/fieldsByRecordType/${recordType}`;
	return rehabService.get<RehabQueryChoiceList>(address, {
		params: {
			systemType,
			assetType,
			...(inspectionStandard ? { inspectionStandard } : {}),
			...(treeId ? { treeId } : {}),
		},
	});
};
