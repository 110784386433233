import { put, retry, takeLatest } from '@redux-saga/core/effects';
import { AnyAction } from 'redux';
import { getRehabRulesChoiceListApi } from '@Services/ChoiceList/ChoiceList.services';
import {
	RehabQueryChoiceList,
	RehabRuleChoiceListFiles,
} from '@Types/rehab.types';
import {
	getAllRehabRuleChoiceListFiles,
	getAllRehabRuleChoiceListFilesRejected,
	getAllRehabRuleChoiceListFilesResolved,
} from '@Actions/ChoiceList.action';
import { InspectionStandard } from '@Types';
import {
	getPACPDomainValues,
	getWSA05DomainValues,
} from '@innovyze/lib-am-common/Components';

import _ from 'lodash-es';

function* getAllRehabRuleChoiceListFilesSaga(action: AnyAction) {
	try {
		const rehabChoiceLists: { [key: string]: RehabQueryChoiceList } =
			yield retry(
				3,
				30000,
				getRehabRulesChoiceListApi,
				action.payload.systemType,
				action.payload.assetType,
				'All',
				action.payload.inspectionStandard,
				action.payload.treeId,
			);

		const {
			asset,
			userdefined: udt,
			riskComponents,
			inspection,
			condition,
			defect,
			tasks,
			events,
			fullDefect,
			actions,
		} = rehabChoiceLists?.data;

		inspection?.map((ele: any) => {
			if ('fieldOptions' in ele) {
				let domainValues: any = {};
				const keyCamelCase = _.camelCase(ele['key']);
				if (
					action.payload.inspectionStandard == InspectionStandard.PACP
				) {
					if (keyCamelCase == 'shape')
						domainValues = getPACPDomainValues(
							action.payload.t,
							'pipeShape',
						);
					else {
						domainValues = getPACPDomainValues(
							action.payload.t,
							_.camelCase(ele['key']),
						);
					}
				} else if (
					action.payload.inspectionStandard ==
					InspectionStandard.WSA05
				) {
					if (keyCamelCase == 'conduitLocationRiskFactor')
						domainValues = getWSA05DomainValues(
							action.payload.t,
							'conduitLocationRiskFactorObservedByOperator',
						);
					else {
						domainValues = getWSA05DomainValues(
							action.payload.t,
							_.camelCase(ele['key']),
						);
					}
				}
				if (!_.isEmpty(domainValues)) {
					const newOptions = Object.keys(domainValues).map(
						(ele: string) => {
							return {
								display_name: `${ele} - ${domainValues[ele]}`,
								value: ele,
							};
						},
					);
					ele['fieldOptions'] = newOptions;
				}
			}
		});

		const payload: RehabRuleChoiceListFiles = {
			assetRecordType:
				asset?.sort(
					(
						a: { display_name: string },
						b: { display_name: string },
					) =>
						a.display_name.localeCompare(
							b.display_name,
							undefined,
							{
								sensitivity: 'base',
							},
						),
				) || [],
			inspectionRecordType:
				inspection?.sort(
					(
						a: { display_name: string },
						b: { display_name: string },
					) =>
						a.display_name.localeCompare(
							b.display_name,
							undefined,
							{
								sensitivity: 'base',
							},
						),
				) || [],
			riskRecordType:
				riskComponents?.sort(
					(a: { name: string }, b: { name: string }) =>
						a.name.localeCompare(b.name, undefined, {
							sensitivity: 'base',
						}),
				) || [],
			conditionRecordType:
				condition?.sort(
					(
						a: { display_name: string },
						b: { display_name: string },
					) =>
						a.display_name.localeCompare(
							b.display_name,
							undefined,
							{
								sensitivity: 'base',
							},
						),
				) || [],
			udtRecordType:
				udt?.sort(
					(a: { tableName: string }, b: { tableName: string }) =>
						a.tableName.localeCompare(b.tableName, undefined, {
							sensitivity: 'base',
						}),
				) || [],
			// Switch defects for the full defects list when the flag is enabled
			defectFilterRecordType: action.payload.shouldShowFullDefects
				? fullDefect?.sort(
						(
							a: { display_name: string },
							b: { display_name: string },
						) =>
							a.display_name.localeCompare(
								b.display_name,
								undefined,
								{
									sensitivity: 'base',
								},
							),
				  ) || []
				: defect?.sort(
						(
							a: { display_name: string },
							b: { display_name: string },
						) =>
							a.display_name.localeCompare(
								b.display_name,
								undefined,
								{
									sensitivity: 'base',
								},
							),
				  ) || [],
			tasksRecordType:
				tasks?.sort((a: { name: string }, b: { name: string }) =>
					a.name.localeCompare(b.name, undefined, {
						sensitivity: 'base',
					}),
				) || [],
			eventsRecordType:
				events?.sort((a: { name: string }, b: { name: string }) =>
					a.name.localeCompare(b.name, undefined, {
						sensitivity: 'base',
					}),
				) || [],
			actionRecordType:
				actions?.sort(
					(a: { actionId: string }, b: { actionId: string }) =>
						a.actionId.localeCompare(b.actionId, undefined, {
							sensitivity: 'base',
						}),
				) || [],
		};

		yield put(getAllRehabRuleChoiceListFilesResolved(payload));
	} catch (e) {
		yield put(getAllRehabRuleChoiceListFilesRejected());
	}
}
function* watchGetAllRehabRuleChoiceListFiles() {
	yield takeLatest(
		getAllRehabRuleChoiceListFiles,
		getAllRehabRuleChoiceListFilesSaga,
	);
}

const sagaArray = [watchGetAllRehabRuleChoiceListFiles()];

export default sagaArray;
