import * as uuid from 'uuid';
import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import {
	Autocomplete,
	Button,
	Checkbox,
	Chip,
	createFilterOptions,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	CurrencyField,
	HTMLInputElementExtended,
	HTMLTextAreaElementExtended,
	isFeatureEnabled,
	LoadingPage,
	STYLOVYZE_FLAGS,
	Text,
	UnitField,
	useIsFeatureEnabled,
	useSettings,
} from '@innovyze/stylovyze';
import { InfoOutlined } from '@mui/icons-material';
import {
	ConfigRecordTypeFieldData,
	DefectCode,
	RuleOptions,
	Rules,
	CompareOptions,
} from '@Types';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import {
	Content,
	FullHeight,
	StyledForm,
	Wrapper,
} from './RuleConfiguration.styles';
import {
	ChoiceListPairs,
	ChoiceListWithDisabled,
	getAllOperatorsForSpecificField,
	getChoiceListFromSpecificFile,
	getFieldOptions,
	getUnitStatus,
	getValueTypeForSpecificOperator,
	getFieldsData,
	getIsDisabledForSpecificField,
	getChoiceListForCompareFields,
} from '@Utils/choiceListFilesUtils';
import {
	FIELDS_TYPES,
	RECORD_TYPE,
	recordTypeList,
} from '@Utils/choiceListMapping';
import useFormikInputValue from '@Components/hooks/useFormikInputValue';
import useYupSchemaCreate from '@Components/hooks/useYupSchemaCreate';
import { getFieldSystemUnitType, unitMapping } from '@Utils/units/units.utils';
import { populateFieldOptions } from '@Actions/ChoiceList.action';
import {
	selectAllRehabRuleChoiceListFiles,
	selectChoiceListIsLoading,
} from '@Selectors/ChoiceList.selectors';
import { isArray } from 'lodash-es';
import { useGlobalization } from '@Translations';
import { getObservationCodes } from '@innovyze/lib-am-common/Utils';
import { getRuleLabel } from '@Components/RulesList';

interface RuleConfigProps {
	groupedRuleId: string;
	ruleCondition: string;
	onAddRule: (rule: Rules) => void;
	onCancelRule: () => void;
	initialRuleValues: Rules;
	setQueryPanelDisabledState: (state: boolean) => void;
	openFilterHandler: () => void;
	isFilter: boolean;
	setTempRule: (rule: Rules) => void;
	allFilters: Rules[];
	setAllFilters: (allFilters: Rules[]) => void;
	systemType: string;
	assetType: string;
	inspectionStandard: string;
}

const operatorWithDisabledCompare = ['BETWEEN', 'BOOLEAN'];
const operatorWithCompareAndValue = ['DAY_SINCE', 'YEAR_SINCE'];

export const RuleConfiguration = ({
	ruleCondition,
	onAddRule,
	onCancelRule,
	initialRuleValues,
	setQueryPanelDisabledState,
	openFilterHandler,
	isFilter,
	setTempRule,
	allFilters,
	setAllFilters,
	systemType,
	assetType,
	inspectionStandard,
}: RuleConfigProps) => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const [unitRequired, setUnitRequired] = useState(false);
	const formRef = useRef<FormikProps<any>>(null); // NOTE: formRef.current might be null before Formik is rendered, if Formik unmounts, or React Strict Mode remounts the component

	// custom hooks
	const { companySettings } = useSettings();
	const defaultExtras = {
		dateFormat: companySettings.dateFormat,
		disableInput: false,
	};
	const [inputField, setValueType, valueType, setExtras] =
		useFormikInputValue('', defaultExtras);
	const [validationSchema, setYupValueType] = useYupSchemaCreate(t);

	// add formik state
	const [fields, setFields] = useState<ChoiceListPairs[]>([]);
	const [operators, setOperators] = useState<ChoiceListPairs[]>([]);

	const [fieldUnit, setFieldUnit] = useState('');
	const choiceListIsLoading = selectChoiceListIsLoading();
	const [useDefault, setUseDefault] = useState(true); // NOTE: This is used to handle display value for uncontrolled Component (UnitField, Currency Field, Custom Behavior TextField etc.)

	const [allowFilter, setAllowFilter] = useState(false);

	const recordTypeChoiceListFiles = selectAllRehabRuleChoiceListFiles();
	const [recordTypeWithConfig, setRecordTypeWithConfig] = useState(false);
	const [configFieldData, setConfigFieldData] =
		useState<ConfigRecordTypeFieldData>({ label: '', data: [] });

	// Feature Flags
	const shouldShowCustomTableUnits = useIsFeatureEnabled(
		'info-360-asset-rehab-custom-table-units',
	);
	const showActionRecordType = useIsFeatureEnabled(
		'info-360-asset-rehab-new-action-record-type',
	);
	const showEventRecordType = useIsFeatureEnabled(
		'info-360-asset-rehab-new-event-record-type',
	);
	const showMultiRiskModels = isFeatureEnabled(
		STYLOVYZE_FLAGS.REHAB_MULTIPLE_RISK_MODELS,
	);
	const showCustomTables = isFeatureEnabled(
		STYLOVYZE_FLAGS.REHAB_CUSTOM_TABLES_RECORD_TYPE,
	);
	const shouldUseQueryEnhance = isFeatureEnabled(
		'info360-rehab-query-enhancement',
	);

	const allRecordTypeWithConfig: string[] = showMultiRiskModels
		? [
				RECORD_TYPE.RISK,
				RECORD_TYPE.USER_DEFINED_FIELDS,
				RECORD_TYPE.TASK,
				RECORD_TYPE.EVENT,
				RECORD_TYPE.ACTION,
		  ]
		: [];

	const [configValue, setConfigValue] = useState('');
	const [currentRecordValue, setCurrentRecordValue] = useState('');

	// Additional states for compare to field feature
	const [allowCompareToField, setAllowCompareToField] = useState(false); // compare to field checkbox state
	const [compareFields, setCompareFields] = useState<
		ChoiceListWithDisabled[]
	>([]); // list of available fields for compare to field
	const [compareRecordTypeWithConfig, setCompareRecordTypeWithConfig] =
		useState(false); // indicate if the selected record type has config for compare to field
	const [compareConfigFieldData, setCompareConfigFieldData] =
		useState<ConfigRecordTypeFieldData>({ label: '', data: [] }); // list of available configs for the selected record type for compare to field

	const recordTypeOptions = () => {
		const result = [
			{
				label: t('Asset attributes'),
				value: RECORD_TYPE.ASSET_ATTRIBUTES,
			},
			{
				label: t('Condition'),
				value: RECORD_TYPE.CONDITION,
			},
			{
				label: t('Inspection'),
				value: RECORD_TYPE.INSPECTION,
			},
			{
				label: t('Risk'),
				value: RECORD_TYPE.RISK,
			},
			{
				label: t('Custom Table'),
				value: RECORD_TYPE.USER_DEFINED_FIELDS,
			},
			{
				label: t('Task'),
				value: RECORD_TYPE.TASK,
			},
			{
				label: t('Event'),
				value: RECORD_TYPE.EVENT,
			},
			{
				label: t('Action'),
				value: RECORD_TYPE.ACTION,
			},
		];

		return result.filter(
			record =>
				systemType === 'Sanitary Sewer' ||
				record.value === RECORD_TYPE.ASSET_ATTRIBUTES ||
				record.value === RECORD_TYPE.RISK ||
				record.value === RECORD_TYPE.USER_DEFINED_FIELDS ||
				record.value === RECORD_TYPE.TASK ||
				record.value === RECORD_TYPE.EVENT,
		);
	};

	const recordTypeOptionsFlagged = () => {
		let filteredRecordTypes = recordTypeOptions();
		if (!showCustomTables) {
			filteredRecordTypes = filteredRecordTypes.filter(obj => {
				return obj.value !== RECORD_TYPE.USER_DEFINED_FIELDS;
			});
		}

		if (!shouldUseQueryEnhance) {
			return recordTypeOptions().filter(obj => {
				return (
					obj.value !== RECORD_TYPE.TASK &&
					obj.value !== RECORD_TYPE.EVENT
				);
			});
		}

		if (!showEventRecordType) {
			filteredRecordTypes = filteredRecordTypes.filter(obj => {
				return obj.value !== RECORD_TYPE.EVENT;
			});
		}

		if (!showActionRecordType) {
			filteredRecordTypes = filteredRecordTypes.filter(obj => {
				return obj.value !== RECORD_TYPE.ACTION;
			});
		}
		return filteredRecordTypes;
	};

	const useStyles = makeStyles(() =>
		createStyles({
			paper: {
				width: 550,
			},
			newRuleNote: {
				backgroundColor: '#F1F4F4',
				margin: '.625rem',
				display: 'flex',
				alignItems: 'center',
				paddingBottom: '1rem',
			},
			newRuleNoteColor: {
				color: '#FD7B32',
			},
			newRuleNoteText: {
				//fontWeight:'300',
			},
			operatorSection: {
				marginTop: '2rem',
			},
			newRuleNoteIcon: {
				marginRight: '0.2rem',
				marginBottom: 'auto',
				marginTop: '0.1rem',
				fontSize: '.8rem',
			},
			LeftFilterNumButton: {
				marginBottom: '0.5rem',
				justifyContent: 'flex-start',
				backgroundColor: '#83BC40',
				borderRadius: '50px',
				cursor: 'text',
				'&:hover': {
					backgroundColor: '#83BC40',
				},
			},
			ActionDiv: {
				paddingRight: '1.5rem',
			},
			LeftButton: {
				justifyContent: 'flex-start',
			},
			RightButton: {
				justifyContent: 'flex-end',
			},
			defectRoot: {
				'& .MuiChip-root': {
					background: '#F1F4F4',
				},
				'& .MuiChip-label': {
					fontSize: '12px',
				},
			},
			codeListTag: {
				background: '#F1F4F4',
			},
			codeListOption: {
				'&[aria-selected="true"]': {
					background: '#007CA0',
					color: 'white',
				},
			},
			CompareToFieldCheckbox: {
				'&.Mui-checked': {
					color: '#757575',
				},
			},
		}),
	);

	const classes = useStyles();

	useEffect(() => {
		if (isFilter) {
			const recordTypeFields: ChoiceListPairs[] =
				getChoiceListFromSpecificFile(
					recordTypeChoiceListFiles,
					RECORD_TYPE.DEFECT_FILTER,
					'',
				);
			setFields(recordTypeFields);
		}
	}, [isFilter]);

	const getMeasurementUnit = useCallback(
		(newField: string, recordType: string) => {
			if (recordType === RECORD_TYPE.USER_DEFINED_FIELDS) {
				if (!shouldShowCustomTableUnits) {
					return '';
				}

				const customTableName = configValue || initialRuleValues.config;

				const localUnit = recordTypeChoiceListFiles.udtRecordType
					.find(record => record.tableName === customTableName)
					?.fields.find(
						(field: { key: string }) => field.key === newField,
					)?.unit;

				return localUnit || initialRuleValues.unit || '';
			} else if (recordType === RECORD_TYPE.TASK) {
				if (!shouldShowCustomTableUnits) {
					return '';
				}

				const taskTypeId = configValue || initialRuleValues.config;

				const localUnit = recordTypeChoiceListFiles.tasksRecordType
					.find(record => record.uploadId === taskTypeId)
					?.fields.find(
						(field: { key: string }) => field.key === newField,
					)?.unit;

				return localUnit || initialRuleValues.unit || '';
			} else if (recordType === RECORD_TYPE.EVENT) {
				if (!shouldShowCustomTableUnits) {
					return '';
				}

				const eventId = configValue || initialRuleValues.config;

				const localUnit = recordTypeChoiceListFiles.eventsRecordType
					.find(record => record.uploadId === eventId)
					?.fields.find(
						(field: { key: string }) => field.key === newField,
					)?.unit;

				return localUnit || initialRuleValues.unit || '';
			}

			const fieldSystemUnitType = getFieldSystemUnitType(
				systemType,
				assetType,
				recordType,
				newField ?? '',
				inspectionStandard,
			);

			const fieldUnit = unitMapping(fieldSystemUnitType);

			return fieldUnit;
		},
		[
			initialRuleValues,
			systemType,
			assetType,
			inspectionStandard,
			configValue,
		],
	);

	useEffect(() => {
		if (initialRuleValues) {
			// Prepare dropdown options for Config if config is provided
			if (
				initialRuleValues.recordType &&
				initialRuleValues.config &&
				allRecordTypeWithConfig.includes(initialRuleValues.recordType)
			) {
				setRecordTypeWithConfig(true);
				setConfigValue(initialRuleValues.config); // this config is uuid instead of name?
				setConfigFieldData(getConfigData(initialRuleValues.recordType));
			}
			// Prepare dropdown options for Field
			if (
				initialRuleValues.recordType &&
				recordTypeList.includes(initialRuleValues.recordType)
			) {
				const recordTypeFields: ChoiceListPairs[] =
					getChoiceListFromSpecificFile(
						recordTypeChoiceListFiles,
						initialRuleValues.recordType,
						initialRuleValues.config,
					);
				setFields(recordTypeFields);
				setCurrentRecordValue(initialRuleValues.recordType);
			}
			if (
				initialRuleValues.field &&
				initialRuleValues.recordType != null
			) {
				// Decide if filter is allowed
				if (
					(initialRuleValues.recordType == RECORD_TYPE.INSPECTION &&
						initialRuleValues.field.toLowerCase() ==
							'Defect_Count'.toLowerCase()) ||
					initialRuleValues.field.toLowerCase() ==
						'Defect_Count_Unit_Length'.toLowerCase()
				) {
					setAllowFilter(true);
				}
				// Prepare dropdown options for Operator
				const fieldOperators: ChoiceListPairs[] =
					getAllOperatorsForSpecificField(
						recordTypeChoiceListFiles,
						initialRuleValues.recordType,
						initialRuleValues.field,
						initialRuleValues.config,
					);
				setOperators(fieldOperators);
				// Prepare dropdown for multi-select field options
				const fieldOptions = getFieldOptions(
					recordTypeChoiceListFiles,
					initialRuleValues.recordType,
					initialRuleValues.field,
					initialRuleValues.config,
					// inspectionStandard,
				);
				dispatch(
					populateFieldOptions({
						fieldOptions: fieldOptions ? fieldOptions : [],
					}),
				);
			}
			// Set validation schema and unit requirements
			if (
				initialRuleValues.operator &&
				initialRuleValues.recordType != null &&
				initialRuleValues.field != null
			) {
				const valueInputType = getValueTypeForSpecificOperator(
					recordTypeChoiceListFiles,
					initialRuleValues.recordType,
					initialRuleValues.field,
					initialRuleValues.operator,
					initialRuleValues.config,
				);
				setYupValueType(valueInputType);
				setValueType(valueInputType);
				const unitStatus = getUnitStatus(
					recordTypeChoiceListFiles,
					initialRuleValues.recordType,
					initialRuleValues.field,
					initialRuleValues.config,
				);
				setUnitRequired(unitStatus);
				const fieldUnit = getMeasurementUnit(
					initialRuleValues.field,
					initialRuleValues.recordType,
				);
				setFieldUnit(fieldUnit);
			}
			// Update allFilters given initialRuleValues.filters
			if (
				(!allFilters || allFilters.length == 0) &&
				initialRuleValues &&
				initialRuleValues.filters &&
				initialRuleValues.filters.length > 0
			) {
				setAllFilters(initialRuleValues.filters);
			}
			// Prepare Compare section if compare to field is allowed
			if (
				initialRuleValues.compare &&
				(initialRuleValues.compare as CompareOptions).recordType &&
				(initialRuleValues.compare as CompareOptions).field &&
				initialRuleValues.operator &&
				initialRuleValues.recordType &&
				initialRuleValues.field
			) {
				setAllowCompareToField(true); // check the checkbox
				if (initialRuleValues.recordType === RECORD_TYPE.ACTION) {
					setValueType(FIELDS_TYPES.PERCENTAGE_TEXT);
					setYupValueType(FIELDS_TYPES.COMPARE_WITH_VALUE);
				} else if (
					initialRuleValues.operator === 'DAYS_SINCE' ||
					initialRuleValues.operator === 'YEARS_SINCE'
				) {
					setYupValueType(FIELDS_TYPES.COMPARE_WITH_VALUE);
				} else {
					setExtras({
						dateFormat: companySettings.dateFormat,
						disableInput: true,
					}); // disable value input
					setYupValueType(FIELDS_TYPES.COMPARE);
				}
				// Prepare dropdown options for Compare Config
				if (
					initialRuleValues.config &&
					allRecordTypeWithConfig.includes(
						(initialRuleValues.compare as CompareOptions)
							.recordType,
					)
				) {
					setCompareRecordTypeWithConfig(true);
					setCompareConfigFieldData(
						getConfigData(
							(initialRuleValues.compare as CompareOptions)
								.recordType,
						),
					);
				}
				// Prepare dropdown options for Compare Field
				const recordTypeFields: ChoiceListWithDisabled[] =
					getChoiceListForCompareFields(
						recordTypeChoiceListFiles,
						initialRuleValues.operator,
						initialRuleValues.recordType,
						initialRuleValues.field,
						(initialRuleValues.compare as CompareOptions)
							.recordType,
						initialRuleValues.config,
						(initialRuleValues.compare as CompareOptions).config,
					);
				setCompareFields(recordTypeFields);
			}
		}
	}, [initialRuleValues]);

	const codes: DefectCode[] = useMemo(() => {
		const codes = getObservationCodes(inspectionStandard, t);
		return Object.keys(codes).map((key: string) => {
			return { code: key, desc: `${key} - ${codes[key]}` };
		});
	}, [inspectionStandard]);

	const codeStrings: string[] = useMemo(() => {
		const codes = getObservationCodes(inspectionStandard, t);
		return Object.keys(codes).map((key: string) => {
			return key;
		});
	}, [inspectionStandard]);

	const getConfigData = (recordType: string): ConfigRecordTypeFieldData => {
		if (recordType === RECORD_TYPE.RISK) {
			/*const data: ChoiceListPairs[] = riskConfigs.map(
				(config: { configId: string; name: string }) => {
					return {
						label: config.name,
						value: config.configId,
					};
				},
			);*/
			const data: ChoiceListPairs[] =
				recordTypeChoiceListFiles.riskRecordType.map(
					(ele: Record<string, unknown>) => {
						return {
							label: ele['name'] as string,
							value: ele['configId'] as string,
						};
					},
				);
			return {
				label: 'Risk Model',
				data: data,
			};
		} else if (recordType === RECORD_TYPE.USER_DEFINED_FIELDS) {
			const data: ChoiceListPairs[] =
				recordTypeChoiceListFiles.udtRecordType?.map((ele: any) => {
					return {
						label: ele['tableName'],
						value: ele['tableName'],
					};
				});
			return {
				label: 'Table Name',
				data: data,
			};
		} else if (recordType === RECORD_TYPE.TASK) {
			const data: ChoiceListPairs[] =
				recordTypeChoiceListFiles.tasksRecordType.map(
					(ele: Record<string, unknown>) => {
						return {
							label: ele['name'] as string,
							value: ele['uploadId'] as string,
						};
					},
				);
			return {
				label: 'Task',
				data: data,
			};
		} else if (recordType === RECORD_TYPE.EVENT) {
			const data: ChoiceListPairs[] =
				recordTypeChoiceListFiles.eventsRecordType.map(
					(ele: Record<string, unknown>) => {
						return {
							label: ele['name'] as string,
							value: ele['uploadId'] as string,
						};
					},
				);
			return {
				label: 'Event',
				data: data,
			};
		} else if (recordType === RECORD_TYPE.ACTION) {
			const data: ChoiceListPairs[] =
				recordTypeChoiceListFiles.actionRecordType.map(
					(ele: Record<string, unknown>) => {
						return {
							label: ele['actionId'] as string,
							value: ele['_id'] as string,
						};
					},
				);
			return {
				label: 'Action',
				data: data,
			};
		}
		return {
			label: '',
			data: [],
		};
	};

	const addRuleToQuery = (newRule: Rules) => {
		onAddRule(newRule);
	};

	// after formik functions
	const handleRecordTypeChange = (event: SelectChangeEvent<unknown>) => {
		formRef?.current?.resetForm();
		const newRecordType = event.target.value as string;
		formRef?.current?.setFieldValue('recordType', newRecordType, false);
		formRef?.current?.setFieldValue('config', '', false);
		formRef?.current?.setFieldValue('field', '', false);
		formRef?.current?.setFieldValue('operator', '', false);
		formRef?.current?.setFieldValue('value1', '', false);
		formRef?.current?.setFieldValue('value2', '', false);
		formRef?.current?.setFieldValue('unitLength', '', false);
		formRef?.current?.setFieldValue('compare', {}, false);
		setAllowCompareToField(false);
		setFieldUnit('');
		setExtras(defaultExtras);
		setCurrentRecordValue(newRecordType);
		if (newRecordType && recordTypeList.includes(newRecordType)) {
			if (allRecordTypeWithConfig.includes(newRecordType)) {
				setRecordTypeWithConfig(true);
				setConfigFieldData(getConfigData(newRecordType));
			} else {
				const recordTypeFields: ChoiceListPairs[] =
					getChoiceListFromSpecificFile(
						recordTypeChoiceListFiles,
						newRecordType,
						'',
					);
				setFields(recordTypeFields);
				setRecordTypeWithConfig(false);
			}
		}
	};

	const handleCompareRecordTypeChange = (
		event: SelectChangeEvent<unknown>,
		values: FormikValues,
	) => {
		const newCompareRecordType = event.target.value as string;
		formRef?.current?.resetForm({ values });
		formRef?.current?.setFieldValue(
			'compare.recordType',
			newCompareRecordType,
			false,
		);
		formRef?.current?.setFieldValue('compare.config', '', false);
		formRef?.current?.setFieldValue('compare.field', '', false);

		if (
			newCompareRecordType &&
			recordTypeList.includes(newCompareRecordType)
		) {
			if (allRecordTypeWithConfig.includes(newCompareRecordType)) {
				setCompareRecordTypeWithConfig(true);
				setCompareConfigFieldData(getConfigData(newCompareRecordType));
			} else {
				const recordTypeFields: ChoiceListWithDisabled[] =
					getChoiceListForCompareFields(
						recordTypeChoiceListFiles,
						values.operator,
						values.recordType,
						values.field,
						newCompareRecordType,
						configValue,
					);
				setCompareFields(recordTypeFields);
				setCompareRecordTypeWithConfig(false);
			}
		}
	};

	const handleConfigChange = (event: SelectChangeEvent<unknown>) => {
		const newConfigValue = event.target.value as string;
		formRef?.current?.setFieldValue('config', newConfigValue, false);
		formRef?.current?.setFieldValue('field', '', false);
		formRef?.current?.setFieldValue('operator', '', false);
		formRef?.current?.setFieldValue('value1', '', false);
		formRef?.current?.setFieldValue('value2', '', false);
		formRef?.current?.setFieldValue('unitLength', '', false);
		formRef?.current?.setFieldValue('compare', {}, false);
		setFieldUnit('');
		setAllowCompareToField(false);
		setExtras(defaultExtras);
		setConfigValue(newConfigValue);

		const recordTypeFields: ChoiceListPairs[] =
			getChoiceListFromSpecificFile(
				recordTypeChoiceListFiles,
				currentRecordValue,
				newConfigValue,
			);

		setFields(recordTypeFields);
	};

	const handleCompareConfigChange = (
		event: SelectChangeEvent<unknown>,
		values: FormikValues,
	) => {
		const newConfigValue = event.target.value as string;
		formRef?.current?.resetForm({ values });
		formRef?.current?.setFieldValue(
			'compare.config',
			newConfigValue,
			false,
		);
		formRef?.current?.setFieldValue('compare.field', '', false);

		const recordTypeFields: ChoiceListWithDisabled[] =
			getChoiceListForCompareFields(
				recordTypeChoiceListFiles,
				values.operator,
				values.recordType,
				values.field,
				values.compare.recordType,
				configValue,
				newConfigValue,
			);

		setCompareFields(recordTypeFields);
	};

	const handleFieldChange = (
		event: SelectChangeEvent<unknown>,
		values: FormikValues,
	) => {
		const newField = event.target.value as string;
		formRef?.current?.resetForm({ values });
		formRef?.current?.setFieldValue('field', newField, false);
		formRef?.current?.setFieldValue('operator', '', false);
		formRef?.current?.setFieldValue('value1', '', false);
		formRef?.current?.setFieldValue('value2', '', false);
		formRef?.current?.setFieldValue('unitLength', '', false);
		formRef?.current?.setFieldValue('compare', {}, false);
		setFieldUnit('');
		setAllowCompareToField(false);
		setExtras(defaultExtras);
		if (isFilter) {
			formRef?.current?.setFieldValue(
				'recordType',
				RECORD_TYPE.DEFECT_FILTER,
				false,
			);
		}
		if (newField) {
			let recordType = values.recordType;
			if (isFilter) {
				recordType = RECORD_TYPE.DEFECT_FILTER;
				setAllowFilter(false);
			} else {
				if (
					recordType == RECORD_TYPE.INSPECTION &&
					(newField.toLowerCase() == 'Defect_Count'.toLowerCase() ||
						newField.toLowerCase() ==
							'Defect_Count_Unit_Length'.toLowerCase())
				) {
					setAllowFilter(true);

					// This is so we can validate the Inspection Defect Count Unit Lengths since it's done BEFORE the operators etc..
					setYupValueType(FIELDS_TYPES.NUMERICAL_ONE_FIELD);
					setValueType(FIELDS_TYPES.NUMERICAL_ONE_FIELD);
				} else {
					setAllowFilter(false);
				}
			}

			const fieldUnit = getMeasurementUnit(newField, recordType);
			setFieldUnit(fieldUnit);

			const fieldOperators: ChoiceListPairs[] =
				getAllOperatorsForSpecificField(
					recordTypeChoiceListFiles,
					recordType,
					newField,
					configValue,
				);
			setOperators(fieldOperators);

			const fieldOptions = getFieldOptions(
				recordTypeChoiceListFiles,
				recordType,
				newField,
				configValue,
				// inspectionStandard,
			);

			dispatch(
				populateFieldOptions({
					fieldOptions: fieldOptions ? fieldOptions : [],
				}),
			);
		}
	};

	const handleCompareFieldChange = (
		event: SelectChangeEvent<unknown>,
		values: FormikValues,
	) => {
		const newField = event.target.value as string;
		formRef?.current?.resetForm({ values });
		formRef?.current?.setFieldValue('compare.field', newField, false);
	};

	const isDisabled = (key: string) => {
		if (currentRecordValue === RECORD_TYPE.ASSET_ATTRIBUTES) {
			const isFieldDisabled: boolean = getIsDisabledForSpecificField(
				recordTypeChoiceListFiles,
				currentRecordValue,
				key,
				'',
			);
			return isFieldDisabled;
		}
		return false;
	};

	const handleOperatorChange = (
		event: SelectChangeEvent<unknown>,
		values: FormikValues,
	) => {
		const newOperator = event.target.value as string;
		if (newOperator) {
			let recordType = values.recordType;
			if (isFilter) {
				recordType = RECORD_TYPE.DEFECT_FILTER;
			}

			const valueInputType = getValueTypeForSpecificOperator(
				recordTypeChoiceListFiles,
				recordType,
				values.field,
				newOperator,
				configValue,
			);
			formRef?.current?.resetForm({ values });
			formRef?.current?.setFieldValue('operator', newOperator, false);
			formRef?.current?.setFieldValue('value1', '', false);
			formRef?.current?.setFieldValue('value2', '', false);
			formRef?.current?.setFieldValue('unitLength', '', false);
			formRef?.current?.setFieldValue('compare', {}, false);
			setYupValueType(valueInputType);
			setValueType(valueInputType);
			setAllowCompareToField(false);
			setExtras(defaultExtras);
			setUseDefault(false); // To reset uncontrolled components
			const unitStatus = getUnitStatus(
				recordTypeChoiceListFiles,
				recordType,
				values.field,
				configValue,
			);
			setUnitRequired(unitStatus);
		}
	};

	const handleAllowCompareToFieldChange = (
		event: ChangeEvent<HTMLInputElement>,
		values: FormikValues,
	) => {
		const checked = event.target.checked;
		setAllowCompareToField(checked);
		if (checked) {
			formRef?.current?.resetForm({ values });
			if (
				values.recordType !== RECORD_TYPE.ACTION &&
				values.operator !== 'DAYS_SINCE' &&
				values.operator !== 'YEARS_SINCE'
			) {
				formRef?.current?.setFieldValue('value1', '', false);
				formRef?.current?.setFieldValue('value2', '', false);

				setYupValueType(FIELDS_TYPES.COMPARE);
				setExtras({
					dateFormat: companySettings.dateFormat,
					disableInput: true,
				});
			} else if (values.recordType === RECORD_TYPE.ACTION) {
				// When we compare action to another field, we allow user to set percentage (default to 100%)
				// eg. Action.cost == 10% of Task.budget
				setValueType(FIELDS_TYPES.PERCENTAGE_TEXT);
				formRef?.current?.setFieldValue('value1', 100, false);
				setYupValueType(FIELDS_TYPES.COMPARE_WITH_VALUE);
				setExtras(defaultExtras);
			} else {
				setYupValueType(FIELDS_TYPES.COMPARE_WITH_VALUE);
				setExtras(defaultExtras);
			}
			return;
		}

		// When unchecking the Checkbox
		formRef?.current?.setFieldValue('compare', {}, false); // clear compare values
		if (values.recordType === RECORD_TYPE.ACTION) {
			setValueType(FIELDS_TYPES.CURRENCY_ONE_FIELD); // reset value input component back to currency
			setYupValueType(FIELDS_TYPES.CURRENCY_ONE_FIELD); // reset yup schema
		} else {
			setYupValueType(valueType); // reset yup schema
			setExtras(defaultExtras); // enable input component
		}
	};

	const generateCompareField = (
		values: FormikValues,
		errors: FormikErrors<FormikValues>,
	) => {
		return (
			<>
				<Grid item xs={12}>
					<FormControl
						variant="outlined"
						error={
							!!(errors?.compare as unknown as CompareOptions)
								?.recordType
						}
						fullWidth>
						<InputLabel
							htmlFor="compareRecordType-select"
							id="compareRecordType-label">
							{t('Record Type')}
						</InputLabel>
						<Select
							labelId="compareRecordType-label"
							id="compareRecordType"
							value={values.compare?.recordType}
							label={t('Record Type')}
							onChange={(event: SelectChangeEvent<unknown>) => {
								handleCompareRecordTypeChange(event, values);
							}}>
							{recordTypeOptionsFlagged().map(
								({ label, value }) => (
									<MenuItem key={value} value={value}>
										{label}
									</MenuItem>
								),
							)}
						</Select>
						{!!(errors?.compare as unknown as CompareOptions)
							?.recordType && (
							<FormHelperText>
								{
									(
										errors?.compare as unknown as CompareOptions
									)?.recordType
								}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>

				{values.compare?.recordType &&
					!(errors?.compare as unknown as CompareOptions)
						?.recordType && (
						<>
							{compareRecordTypeWithConfig && (
								<Grid item xs={12}>
									<FormControl
										variant="outlined"
										error={
											!!(
												errors?.compare as unknown as CompareOptions
											)?.config
										}
										fullWidth>
										<InputLabel
											htmlFor="compareConfig-select"
											id="compareConfig-label">
											{compareConfigFieldData.label}
										</InputLabel>
										<Select
											labelId="compareConfig-label"
											id="compareConfig"
											value={values.compare?.config}
											label={compareConfigFieldData.label}
											onChange={(
												event: SelectChangeEvent<unknown>,
											) => {
												handleCompareConfigChange(
													event,
													values,
												);
											}}>
											{compareConfigFieldData.data.map(
												({ label, value }) => (
													<MenuItem
														key={value}
														value={value}>
														{label}
													</MenuItem>
												),
											)}
										</Select>
										{compareRecordTypeWithConfig &&
											values.compare?.field &&
											!values.compare?.config && (
												<FormHelperText>
													{t('Config is required')}
												</FormHelperText>
											)}
									</FormControl>
								</Grid>
							)}
							{(!compareRecordTypeWithConfig ||
								values.compare?.config) && (
								<Grid item xs={12}>
									<FormControl
										variant="outlined"
										error={
											!!(
												errors?.compare as unknown as CompareOptions
											)?.field
										}
										fullWidth>
										<InputLabel
											htmlFor="compareField-select"
											id="compareField-label">
											{t('Field')}
										</InputLabel>
										<Select
											labelId="compareField-label"
											id="compareField"
											value={values.compare.field}
											label={t('Field')}
											onChange={(
												event: SelectChangeEvent<unknown>,
											) => {
												handleCompareFieldChange(
													event,
													values,
												);
											}}>
											{compareFields.map(
												({
													label,
													value,
													disabled,
												}) => (
													<MenuItem
														key={value}
														value={value}
														disabled={disabled}>
														{label}
													</MenuItem>
												),
											)}
										</Select>
										{(
											errors?.compare as unknown as CompareOptions
										)?.field && (
											<FormHelperText>
												{
													(
														errors?.compare as unknown as CompareOptions
													)?.field
												}
											</FormHelperText>
										)}
									</FormControl>
								</Grid>
							)}
						</>
					)}
			</>
		);
	};

	const setAdjustedFieldValue = (
		field: string,
		event: ChangeEvent<
			HTMLInputElementExtended | HTMLTextAreaElementExtended
		>,
	) => {
		const value = parseFloat(event.target.value);
		// const valueUnit = event.target.originalValue
		// 	? parseFloat(event.target.originalValue)
		// 	: undefined;

		// Some imperial conversions dont come back correctely and need an adjustment
		// if (valueUnit !== undefined && value != valueUnit) {
		// 	// mm -> in -> cm
		// 	if (fieldUnit === UNITS.MILLIMETER) value *= 10;
		// }

		formRef?.current?.setFieldValue(field, value);
	};

	const setYearFieldValue = (
		field: string,
		event: ChangeEvent<
			HTMLInputElementExtended | HTMLTextAreaElementExtended
		>,
	) => {
		let value = parseFloat(event.target.value);
		// Do this because smaller values i.e. 18 just get made into 1918
		if (isNaN(value) || value < 1900) value = 1899;
		const date = new Date(value, 1, 2, 12, 0);
		const dateString = date.toISOString();

		formRef?.current?.setFieldValue(field, dateString);
	};

	const [filtersConditionNotMet, setFiltersConditionNotMet] = useState(true); //controls when to enable save button
	//following function checks if any filters have been added to the query
	const checkFiltersForDefectPerUnitLength = () => {
		let filtersFound = false;
		if (allFilters && allFilters.length > 0)
			for (const filter of allFilters)
				if (
					filter.field &&
					['PACP_CODE', 'WSA_CODE'].includes(filter.field) &&
					filter?.value1
				)
					if (
						typeof filter.value1 === 'object' &&
						filter.value1.length > 0
					) {
						filtersFound = true;
						break;
					}
		return filtersFound;
	};
	//following useEffect is to enable/disable save btn when filters are added/removed from query
	useEffect(() => {
		const flag = checkFiltersForDefectPerUnitLength();
		setFiltersConditionNotMet(!flag);
	}, [allFilters]);

	const getFieldLabel = (recordType: string, field: string) => {
		const selectedField = fields.find(value => value.value == field);
		return getRuleLabel(
			t,
			recordType,
			selectedField ? selectedField.label : field,
			selectedField ? selectedField.value : field,
		);
	};

	const generateValueField = (
		values: FormikValues,
		errors: FormikErrors<FormikValues>,
	) => {
		let valueFields = <></>;
		const disableValueField =
			allowCompareToField &&
			!operatorWithCompareAndValue.includes(values.operator);

		if (
			values.field.toLowerCase() == 'PACP_Code'.toLowerCase() ||
			values.field.toLowerCase() == 'WSA_Code'.toLowerCase()
		) {
			return (
				<Grid xs={12} item>
					<Autocomplete
						disabled={disableValueField}
						className={classes.defectRoot}
						classes={{
							tag: classes.codeListTag,
							option: classes.codeListOption,
						}}
						filterOptions={filterOptions}
						multiple
						id="defectCodes"
						freeSolo
						options={codeStrings}
						defaultValue={
							useDefault &&
							initialRuleValues.value1 &&
							Array.isArray(initialRuleValues.value1)
								? (initialRuleValues.value1 as string[])
								: []
						}
						getOptionLabel={option => {
							const defectcode = codes.find(
								code => code.code == option,
							);
							if (defectcode) return defectcode.desc;
							else return `${option}`;
						}}
						renderInput={params => (
							<TextField
								{...params}
								variant="outlined"
								label={t('Codes')}
							/>
						)}
						onChange={(_event, newValue) => {
							formRef.current?.setFieldValue(
								'value1',
								newValue,
								false,
							);
						}}
						renderTags={(values, getTagProps) =>
							values.map((value, index) => (
								<Tooltip
									key={value as string}
									title={
										codes.find(code => code.code == value)
											?.desc ?? ''
									}>
									<Chip
										{...getTagProps({ index })}
										label={value as string}
									/>
								</Tooltip>
							))
						}
					/>
				</Grid>
			);
		}

		if (values.operator == 'BOOLEAN') {
			valueFields = (
				<Grid item xs={12}>
					<FormControl
						variant="outlined"
						disabled={disableValueField}
						error={errors && errors.value1 ? true : false}
						fullWidth>
						{inputField}
						{errors && errors.value1 && (
							<FormHelperText error={true}>
								{errors.value1}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
			);

			return valueFields;
		} else if (
			(valueType === FIELDS_TYPES.NUMERICAL_ONE_FIELD ||
				valueType === FIELDS_TYPES.NUMERICAL_UNRESTRICTED_ONE_FIELD) &&
			unitRequired
		) {
			valueFields = (
				<Grid item xs={12}>
					<FormControl
						variant="outlined"
						error={errors && errors.value1 ? true : false}
						fullWidth>
						{fieldUnit ? (
							<UnitField
								fullWidth
								disabled={disableValueField}
								name="value1"
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElementExtended
										| HTMLTextAreaElementExtended
									>,
								) => {
									setAdjustedFieldValue('value1', event);
								}}
								defaultValue={
									useDefault && initialRuleValues.value1
										? initialRuleValues.value1.toString()
										: ''
								}
								unit={fieldUnit}
								step={'any'}
								variant="outlined"
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								error={errors && errors?.value1 ? true : false}
							/>
						) : (
							<TextField
								fullWidth
								disabled={disableValueField}
								name="value1"
								value={values.value1}
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElementExtended
										| HTMLTextAreaElementExtended
									>,
								) => {
									setAdjustedFieldValue('value1', event);
								}}
								type={'number'}
								variant="outlined"
								error={errors && errors?.value1 ? true : false}
							/>
						)}
						{errors && errors.value1 && (
							<FormHelperText error={true}>
								{errors.value1}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
			);
			return valueFields;
		} else if (valueType === FIELDS_TYPES.CURRENCY_ONE_FIELD) {
			valueFields = (
				<Grid item xs={12}>
					<FormControl
						variant="outlined"
						error={errors && errors.value1 ? true : false}
						fullWidth>
						<CurrencyField
							fullWidth
							disabled={disableValueField}
							name="value1"
							onChange={(
								event: ChangeEvent<
									| HTMLInputElementExtended
									| HTMLTextAreaElementExtended
								>,
							) => {
								setAdjustedFieldValue('value1', event);
							}}
							defaultValue={
								useDefault && initialRuleValues.value1
									? initialRuleValues.value1.toString()
									: ''
							}
							variant="outlined"
							inputProps={{
								inputMode: 'numeric',
								pattern: '[0-9]*',
							}}
							error={errors && errors?.value1 ? true : false}
						/>
						{errors && errors.value1 && (
							<FormHelperText error={true}>
								{errors.value1}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
			);
			return valueFields;
		} else if (
			(valueType === FIELDS_TYPES.NUMERICAL_TWO_FIELDS ||
				valueType === FIELDS_TYPES.NUMERICAL_UNRESTRICTED_TWO_FIELDS) &&
			unitRequired
		) {
			valueFields = (
				<>
					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							error={errors && errors.value1 ? true : false}
							fullWidth>
							<UnitField
								fullWidth
								disabled={disableValueField}
								name="value1"
								value={values.value1}
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElement
										| HTMLTextAreaElementExtended
									>,
								) => {
									setAdjustedFieldValue('value1', event);
								}}
								defaultValue={
									useDefault && initialRuleValues.value1
										? initialRuleValues.value1.toString()
										: ''
								}
								unit={fieldUnit}
								step={'any'}
								label={t('Value 1')}
								variant="outlined"
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								error={errors && errors.value1 ? true : false}
							/>
							{errors && errors.value1 && (
								<FormHelperText error={true}>
									{errors.value1}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							error={errors && errors.value2 ? true : false}
							fullWidth>
							<UnitField
								fullWidth
								disabled={disableValueField}
								name="value2"
								value={values.value2}
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElement
										| HTMLTextAreaElementExtended
									>,
								) => {
									setAdjustedFieldValue('value2', event);
								}}
								defaultValue={
									useDefault && initialRuleValues.value2
										? initialRuleValues.value2.toString()
										: ''
								}
								unit={fieldUnit}
								step={'any'}
								label={t('Value 2')}
								variant="outlined"
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								error={errors && errors.value2 ? true : false}
							/>
							{errors && errors.value2 && (
								<FormHelperText error={true}>
									{errors.value2}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
				</>
			);
			return valueFields;
		} else if (valueType === FIELDS_TYPES.CURRENCY_TWO_FIELDS) {
			valueFields = (
				<>
					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							error={errors && errors.value1 ? true : false}
							fullWidth>
							<CurrencyField
								fullWidth
								name="value1"
								value={values.value1}
								disabled={disableValueField}
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElement
										| HTMLTextAreaElementExtended
									>,
								) => {
									setAdjustedFieldValue('value1', event);
								}}
								defaultValue={
									useDefault && initialRuleValues.value1
										? initialRuleValues.value1.toString()
										: ''
								}
								label={t('Value 1')}
								variant="outlined"
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								error={errors && errors.value1 ? true : false}
							/>
							{errors && errors.value1 && (
								<FormHelperText error={true}>
									{errors.value1}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							error={errors && errors.value2 ? true : false}
							fullWidth>
							<CurrencyField
								fullWidth
								disabled={disableValueField}
								name="value2"
								value={values.value2}
								onChange={(
									event: ChangeEvent<
										| HTMLInputElement
										| HTMLTextAreaElementExtended
									>,
								) => {
									setAdjustedFieldValue('value2', event);
								}}
								defaultValue={
									useDefault && initialRuleValues.value2
										? initialRuleValues.value2.toString()
										: ''
								}
								label={t('Value 2')}
								variant="outlined"
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								error={errors && errors.value2 ? true : false}
							/>
							{errors && errors.value2 && (
								<FormHelperText error={true}>
									{errors.value2}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
				</>
			);
			return valueFields;
		} else if (valueType === FIELDS_TYPES.DATE_YEAR_ONE_FIELD) {
			valueFields = (
				<Grid item xs={12}>
					<FormControl
						variant="outlined"
						error={errors && errors.value1 ? true : false}
						fullWidth>
						<TextField
							fullWidth
							disabled={disableValueField}
							name="value1"
							// value={values.value1} // FIXME: no Formik Control
							onChange={(
								// eslint-disable-next-line prettier/prettier
								event: ChangeEvent<
									| HTMLInputElementExtended
									| HTMLTextAreaElementExtended
								>,
							) => {
								setYearFieldValue('value1', event);
							}}
							defaultValue={
								useDefault &&
								initialRuleValues.value1 &&
								Array.isArray(initialRuleValues.value1) &&
								initialRuleValues.value1.length
									? new Date(
											(
												initialRuleValues.value1 as Array<any>
											)[0],
									  ).getFullYear()
									: ''
							}
							label={t('Year')}
							variant="outlined"
							type="number"
							inputProps={{
								min: 1900,
								max: 2200,
								step: 1,
							}}
							error={errors && errors.value1 ? true : false}
							helperText={(errors && errors.value1) ?? ''}
						/>
					</FormControl>
				</Grid>
			);
			return valueFields;
		} else if (valueType === FIELDS_TYPES.DATE_YEAR_TWO_FIELDS) {
			valueFields = (
				<>
					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							error={errors && errors.value1 ? true : false}
							fullWidth>
							<TextField
								fullWidth
								disabled={disableValueField}
								name="value1"
								// value={values.value1} // FIXME: no Formik Control
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElement
										| HTMLTextAreaElementExtended
									>,
								) => {
									setYearFieldValue('value1', event);
								}}
								defaultValue={
									useDefault &&
									initialRuleValues.value1 &&
									Array.isArray(initialRuleValues.value1) &&
									initialRuleValues.value1.length
										? new Date(
												(
													initialRuleValues.value1 as Array<any>
												)[0],
										  ).getFullYear()
										: ''
								}
								label={t('Year From')}
								variant="outlined"
								type="number"
								inputProps={{
									min: 1900,
									max: 2200,
									step: 1,
								}}
								error={errors && errors.value1 ? true : false}
								helperText={(errors && errors.value1) ?? ''}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							error={errors && errors.value2 ? true : false}
							fullWidth>
							<TextField
								fullWidth
								disabled={disableValueField}
								name="value2"
								// value={values.value2} // FIXME: no Formik Control
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElement
										| HTMLTextAreaElementExtended
									>,
								) => {
									setYearFieldValue('value2', event);
								}}
								defaultValue={
									useDefault &&
									initialRuleValues.value2 &&
									Array.isArray(initialRuleValues.value2) &&
									initialRuleValues.value2.length
										? new Date(
												(
													initialRuleValues.value2 as Array<any>
												)[0],
										  ).getFullYear()
										: ''
								}
								label={t('Year To')}
								variant="outlined"
								type="number"
								inputProps={{
									min: 1900,
									max: 2200,
									step: 1,
								}}
								error={errors && errors.value2 ? true : false}
								helperText={(errors && errors.value2) ?? ''}
							/>
						</FormControl>
					</Grid>
				</>
			);
			return valueFields;
		}

		return inputField;
	};

	const getCurrentRule = (values: FormikValues) => {
		const allValues = [] as any;
		let dateValue2 = values.value2;
		if (values.value1 || values.value1 === 0) {
			if (
				valueType === FIELDS_TYPES.MULTI_SELECT_ONE_FIELD &&
				values.value1.length !== 0
			) {
				values.value1.map((val: any) => {
					if (typeof val === 'string') {
						allValues.push(val);
					} else {
						allValues.push(val.value);
					}
				});
			} else if (
				valueType === FIELDS_TYPES.DATE_ONE_FIELD ||
				valueType === FIELDS_TYPES.DATE_TWO_FIELDS
			) {
				//Date picker can only work with local datetime. This adjustment is
				//needed to keep the date true and prevent from the selected date
				//from jumping backward and foward
				const jsDate = new Date(values.value1);
				let dt = DateTime.fromJSDate(jsDate);
				dt = dt.plus({ minutes: dt.offset });
				allValues.push(dt.toUTC().toISO());
				if (
					valueType === FIELDS_TYPES.DATE_TWO_FIELDS &&
					values.value2
				) {
					const jsDate2 = new Date(values.value2);
					let dt2 = DateTime.fromJSDate(jsDate2);
					dt2 = dt2.plus({ minutes: dt2.offset });
					dateValue2 = dt2.toUTC().toISO();
				}
			} else {
				if (isArray(values.value1) && values.value1.length > 0) {
					allValues.push(values.value1[0]);
				} else {
					allValues.push(values.value1);
				}
			}
		}
		const RuleObject: Rules = {
			id: uuid.v4(),
			recordType: values.recordType,
			config: recordTypeWithConfig ? values.config : '',
			field: values.field,
			operator: values.operator,
			value1: allValues,
			value2: dateValue2,
			unitLength: values.unitLength ?? null,
			ruleCondition: ruleCondition
				? ruleCondition
				: initialRuleValues.ruleCondition,
			unit: unitRequired ? (fieldUnit ? fieldUnit : values.unit) : '',
			compare: values.compare ? values.compare : {},
		};

		if (isFilter) {
			RuleObject.recordType = RECORD_TYPE.DEFECT_FILTER;
		}

		if (
			RuleObject.field &&
			(RuleObject.recordType === RECORD_TYPE.USER_DEFINED_FIELDS ||
				RuleObject.recordType === RECORD_TYPE.RISK ||
				RuleObject.recordType === RECORD_TYPE.TASK ||
				RuleObject.recordType === RECORD_TYPE.EVENT)
		) {
			const fieldsData = getFieldsData(
				recordTypeChoiceListFiles,
				RuleObject.recordType,
				RuleObject.config,
			);
			if (fieldsData && RuleObject.operator) {
				const ruleOptions: RuleOptions = {
					valueType: valueType
						? valueType
						: fieldsData[RuleObject.field][0]['operators'][
								RuleObject.operator
						  ]['valueType'],
					type: fieldsData[RuleObject.field][0]['type'],
				};
				RuleObject.options = ruleOptions;
			}
		}

		// Add options when comparing to a field with config
		if (
			(RuleObject.compare as CompareOptions)?.field &&
			(RuleObject.compare as CompareOptions)?.config
		) {
			const fieldsData = getFieldsData(
				recordTypeChoiceListFiles,
				(RuleObject.compare as CompareOptions).recordType,
				(RuleObject.compare as CompareOptions).config,
			);
			if (fieldsData && RuleObject.operator) {
				const compareRuleOptions: RuleOptions = {
					valueType: valueType,
					type: fieldsData[
						(RuleObject.compare as CompareOptions).field
					][0]['type'],
				};
				(RuleObject.compare as CompareOptions).options =
					compareRuleOptions;
			}
		}

		return RuleObject;
	};

	const handleSubmit = (values: FormikValues) => {
		const RuleObject = getCurrentRule(values);
		addRuleToQuery(RuleObject);
		setQueryPanelDisabledState(false);
		formRef?.current?.resetForm();
		setFieldUnit('');
	};

	const showFilter = !isFilter && allowFilter;
	const showFilterNum =
		!isFilter &&
		allowFilter &&
		((initialRuleValues.filters && initialRuleValues.filters.length > 0) ||
			allFilters?.length > 0);
	let checkboxTooltip = t(
		'For example, compare two date fields, or two integer fields, etc.',
	);
	if (
		operatorWithDisabledCompare.includes(formRef?.current?.values?.operator)
	) {
		checkboxTooltip = t(
			'This option is not available for the selected operator',
		);
	} else if (allowFilter) {
		checkboxTooltip = t(
			'This option is not available for rule with filters',
		);
	}

	const filterOptions = createFilterOptions({
		matchFrom: 'start',
	});

	const getFieldItems = (recordType: string) => {
		if (recordType === RECORD_TYPE.RISK) {
			const items = fields
				.filter(
					({ value }) =>
						!value.startsWith('LOF') && !value.startsWith('COF'),
				)
				.map(({ label, value }) => (
					<MenuItem
						key={value}
						value={value}
						disabled={isDisabled(value)}>
						{label}
					</MenuItem>
				));

			const lofItems = fields
				.filter(({ value }) => value.startsWith('LOF'))
				.map(({ label, value }) => (
					<MenuItem
						key={value}
						value={value}
						disabled={isDisabled(value)}>
						{value.startsWith('LOFRaw_') ? (
							<div>
								{label}
								<br />
								<Text
									variant="caption-small"
									style={{
										fontStyle: 'italic',
									}}>
									{t('Score')}
								</Text>
							</div>
						) : (
							<div>
								{label}
								<br />
								<Text
									variant="caption-small"
									style={{
										fontStyle: 'italic',
									}}>
									{t('Weighted Score')}
								</Text>
							</div>
						)}
					</MenuItem>
				));

			if (lofItems.length != 0) {
				items.push(
					<MenuItem key={'lofHeader'} disabled={true}>
						<b>{t('Likelihood of Failure (LOF)')}</b>
					</MenuItem>,
				);
				items.push(...lofItems);
			}

			const cofItems = fields
				.filter(({ value }) => value.startsWith('COF'))
				.map(({ label, value }) => (
					<MenuItem
						key={value}
						value={value}
						disabled={isDisabled(value)}>
						{value.startsWith('COFRaw_') ? (
							<div>
								{label}
								<br />
								<Text
									variant="caption-small"
									style={{
										fontStyle: 'italic',
									}}>
									{t('Score')}
								</Text>
							</div>
						) : (
							<div>
								{label}
								<br />
								<Text
									variant="caption-small"
									style={{
										fontStyle: 'italic',
									}}>
									{t('Weighted Score')}
								</Text>
							</div>
						)}
					</MenuItem>
				));

			if (cofItems.length != 0) {
				items.push(
					<MenuItem key={'lofHeader'} disabled={true}>
						<b>{t('Consequence of Failure (CoF)')}</b>
					</MenuItem>,
				);
				items.push(...cofItems);
			}

			return items;
		} else {
			return fields.map(({ label, value }) => (
				<MenuItem
					key={value}
					value={value}
					disabled={isDisabled(value)}>
					{label}
				</MenuItem>
			));
		}
	};

	return (
		<Grid xs={6} item>
			<Wrapper>
				<Grid xs={12} item>
					<Text variant={'heading-medium'}>
						{isFilter ? t('Filters') : t('Rule Configuration')}
					</Text>
				</Grid>

				<Formik
					initialValues={initialRuleValues}
					enableReinitialize={true}
					validateOnChange={true}
					validationSchema={
						unitRequired
							? validationSchema /* validationSchema.shape({
									unit: yup.number().required(),
							  })*/
							: validationSchema
					}
					innerRef={formRef}
					onSubmit={handleSubmit}>
					{({ values, errors, isSubmitting, submitForm }) => {
						// NOTE: must pass values and errors directly into methods, cuz useRef does not re-render on changes
						return (
							<FullHeight>
								<Content>
									<StyledForm autoComplete="off">
										<Grid container spacing={2}>
											{/* Record Type */}
											{!isFilter && (
												<Grid item xs={12}>
													<FormControl
														variant="outlined"
														error={
															errors.recordType
																? true
																: false
														}
														fullWidth>
														<InputLabel
															htmlFor="outputType-select"
															id="outputType-label">
															{t('Record Type')}
														</InputLabel>

														<Select
															labelId="outputType-label"
															id="outputType"
															value={
																values.recordType
															}
															label={t(
																'Record Type',
															)}
															onChange={
																handleRecordTypeChange
															}>
															{recordTypeOptionsFlagged().map(
																({
																	label,
																	value,
																}) => (
																	<MenuItem
																		key={
																			value
																		}
																		value={
																			value
																		}>
																		{label}
																	</MenuItem>
																),
															)}
														</Select>
														{errors?.recordType && (
															<FormHelperText>
																{
																	errors.recordType
																}
															</FormHelperText>
														)}
													</FormControl>
												</Grid>
											)}
											{/* Config Type + Field Type */}
											{(isFilter ||
												(values.recordType &&
													!errors.recordType &&
													!choiceListIsLoading)) && (
												<>
													{/* Config Type */}
													{recordTypeWithConfig && (
														<Grid item xs={12}>
															<FormControl
																variant="outlined"
																error={
																	errors.config ||
																	(values.field &&
																		!values.config)
																		? true
																		: false
																}
																fullWidth>
																<InputLabel
																	htmlFor="config-select"
																	id="config-label">
																	{t(
																		`${configFieldData.label}`,
																	)}
																</InputLabel>

																<Select
																	labelId="config-label"
																	id="config"
																	value={
																		values.config
																	}
																	label={t(
																		`${configFieldData.label}`,
																	)}
																	onChange={
																		handleConfigChange
																	}>
																	{configFieldData.data.map(
																		({
																			label,
																			value,
																		}) => (
																			<MenuItem
																				key={
																					value
																				}
																				value={
																					value
																				}>
																				{
																					label
																				}
																			</MenuItem>
																		),
																	)}
																</Select>
																{errors?.recordType ||
																	(values.field &&
																		!values.config && (
																			<FormHelperText>
																				{t(
																					'Config is required',
																				)}
																			</FormHelperText>
																		))}
															</FormControl>
														</Grid>
													)}
													{/* Field Type */}
													{(!recordTypeWithConfig ||
														values.config) && (
														<Grid item xs={12}>
															<FormControl
																variant="outlined"
																error={
																	errors.field
																		? true
																		: false
																}
																fullWidth>
																<InputLabel
																	htmlFor="field-select"
																	id="field-label">
																	{t('Field')}
																</InputLabel>

																<Select
																	labelId="field-label"
																	id="field"
																	value={
																		values.field
																	}
																	label={t(
																		'Field',
																	)}
																	onChange={(
																		event: SelectChangeEvent<unknown>,
																	) =>
																		handleFieldChange(
																			event,
																			values,
																		)
																	}>
																	{getFieldItems(
																		values.recordType,
																	)}
																</Select>
																{errors?.field && (
																	<FormHelperText>
																		{
																			errors?.field
																		}
																	</FormHelperText>
																)}
															</FormControl>
															{values.field ===
																'DEFECT_COUNT_UNIT_LENGTH' && (
																<FormControl
																	style={{
																		marginTop:
																			'16px',
																	}}
																	variant="outlined"
																	error={
																		errors &&
																		errors.unitLength
																			? true
																			: false
																	}
																	fullWidth>
																	<UnitField
																		fullWidth
																		name="unitLength"
																		label={t(
																			'Unit length',
																		)}
																		onChange={event => {
																			setAdjustedFieldValue(
																				'unitLength',
																				event,
																			);
																		}}
																		onBlur={event => {
																			setAdjustedFieldValue(
																				'unitLength',
																				event,
																			);
																		}}
																		defaultValue={
																			useDefault &&
																			initialRuleValues.unitLength
																				? initialRuleValues.unitLength.toString()
																				: ''
																		}
																		unit={
																			'm'
																		}
																		step={
																			'any'
																		}
																		variant="outlined"
																		inputProps={{
																			inputMode:
																				'numeric',
																			pattern:
																				'[0-9]*',
																		}}
																		error={
																			errors &&
																			errors?.unitLength
																				? true
																				: false
																		}
																	/>
																	{errors &&
																		errors.unitLength && (
																			<FormHelperText
																				error={
																					true
																				}>
																				{
																					errors.unitLength
																				}
																			</FormHelperText>
																		)}
																</FormControl>
															)}
														</Grid>
													)}
												</>
											)}
											{/* Loading Message */}
											{!isFilter &&
												values.recordType &&
												!errors.recordType &&
												choiceListIsLoading && (
													<LoadingPage
														size="sm"
														title={t(
															'Loading field options..',
														)}
													/>
												)}
											{/* Section with Operator, Value, or Compare to another Field */}
											{values.field &&
												fields.length > 0 &&
												(!recordTypeWithConfig ||
													values.config) && (
													<>
														{/* Section Title */}
														<Grid
															className={
																classes.operatorSection
															}
															item
															xs={12}>
															<Text
																variant={
																	'heading-medium'
																}>
																{t(
																	'{{values}} Options',
																	{
																		values: getFieldLabel(
																			values.recordType,
																			values.field,
																		),
																	},
																)}
															</Text>
														</Grid>
														{/* Section Instruction */}
														<Grid
															item
															xs={12}
															className={
																classes.newRuleNote
															}>
															<InfoOutlined
																className={
																	classes.newRuleNoteIcon
																}
															/>
															<Typography
																className={
																	classes.newRuleNoteText
																}
																variant="caption">
																{t(
																	'Allows you to query ',
																)}
																<span
																	className={
																		classes.newRuleNoteColor
																	}>
																	{t(
																		getFieldLabel(
																			values.recordType,
																			values.field,
																		),
																	)}
																</span>
																{values.field ===
																	'DEFECT_COUNT_UNIT_LENGTH' && (
																	<div>
																		{t(
																			'You must add at least one code filter for this query',
																		)}
																	</div>
																)}
																{values.recordType ===
																	'Action' &&
																	values.field ===
																		'COST' && (
																		<div>
																			{t(
																				'If the action cost is specified per unit length, the total cost will be calculated.',
																			)}
																		</div>
																	)}
															</Typography>
														</Grid>
														{/* Operator */}
														<Grid item xs={12}>
															<FormControl
																variant="outlined"
																error={
																	errors.operator &&
																	!values.operator
																		? true
																		: false
																}
																fullWidth>
																<InputLabel
																	htmlFor="operator-select"
																	id="operator-label">
																	{t(
																		'Operator',
																	)}
																</InputLabel>

																<Select
																	labelId="operator-label"
																	id="operator"
																	name="operator"
																	value={
																		values.operator
																	}
																	label={t(
																		'Operator',
																	)}
																	onChange={(
																		event: SelectChangeEvent<unknown>,
																	) =>
																		handleOperatorChange(
																			event,
																			values,
																		)
																	}>
																	{operators.map(
																		({
																			label,
																			value,
																		}) => (
																			<MenuItem
																				key={
																					value
																				}
																				value={
																					value
																				}>
																				{
																					label
																				}
																			</MenuItem>
																		),
																	)}
																</Select>
																{errors?.operator &&
																	!values.operator && (
																		<FormHelperText>
																			{
																				errors?.operator
																			}
																		</FormHelperText>
																	)}
															</FormControl>
														</Grid>
													</>
												)}
											{/* Generate Value Field Depends on the Operator */}
											{values.operator &&
												generateValueField(
													values,
													errors,
												)}
											{/* Checkbox for switching to compare to another field */}
											{!isFilter && values.operator && (
												<Tooltip
													title={checkboxTooltip}
													slotProps={{
														popper: {
															modifiers: [
																{
																	name: 'offset',
																	options: {
																		offset: [
																			0,
																			-14,
																		],
																	},
																},
															],
														},
													}}>
													<Grid item xs={12}>
														<FormControlLabel
															control={
																<Checkbox
																	checked={
																		allowCompareToField
																	}
																	onChange={(
																		event: ChangeEvent<HTMLInputElement>,
																	) =>
																		handleAllowCompareToFieldChange(
																			event,
																			values,
																		)
																	}
																	name="allowCompareToField"
																	className={
																		classes.CompareToFieldCheckbox
																	}
																	disabled={
																		operatorWithDisabledCompare.includes(
																			values.operator,
																		) ||
																		allowFilter
																	}
																/>
															}
															label={t(
																'Compare to another field of the same data type',
															)}
														/>
													</Grid>
												</Tooltip>
											)}
											{/* Displaying details for comparing to another field */}
											{allowCompareToField &&
												generateCompareField(
													values,
													errors,
												)}
										</Grid>
									</StyledForm>
								</Content>
								{/* Show Filter Num */}
								{showFilterNum && (
									<Grid
										container
										className={classes.ActionDiv}>
										<Grid item xs={4}>
											<Button
												className={
													classes.LeftFilterNumButton
												}
												disableElevation
												disableRipple
												variant="contained"
												data-cy="FilterNum">
												{t(
													'{{num}} {{filterText}} Added',
													{
														num: allFilters?.length,
														filterText:
															allFilters?.length >
															1
																? 'Filters'
																: 'Filter',
													},
												)}
											</Button>
										</Grid>
									</Grid>
								)}
								{/* Action Buttons */}
								<Grid container className={classes.ActionDiv}>
									{/* Open Filter Button */}
									<Grid item xs={6}>
										{showFilter && (
											<Button
												onClick={() => {
													setTempRule(
														getCurrentRule(values),
													);

													openFilterHandler();
												}}
												variant="contained"
												data-cy="Filters">
												{t('Open Filters')}
											</Button>
										)}
									</Grid>
									{/* Cancel and Save Buttons */}
									<Grid
										item
										xs={6}
										style={{ textAlign: 'right' }}>
										<Button
											onClick={() => {
												onCancelRule();
												setQueryPanelDisabledState(
													false,
												);
												formRef?.current?.resetForm();
											}}
											variant="contained"
											color="secondary"
											data-cy="Cancel">
											{t('Cancel')}
										</Button>
										<Button
											style={{ marginLeft: '0.5rem' }}
											color="primary"
											disabled={
												isSubmitting ||
												(values.field ===
													'DEFECT_COUNT_UNIT_LENGTH' &&
													filtersConditionNotMet)
											}
											onClick={submitForm}
											variant="contained"
											data-cy="Ok">
											{isFilter
												? t('Add Filter')
												: t('Save')}
										</Button>
									</Grid>
								</Grid>
							</FullHeight>
						);
					}}
				</Formik>
			</Wrapper>
		</Grid>
	);
};

export default RuleConfiguration;
