import { axiosWithHeaders } from '@innovyze/lib_get_service';
import config from './config.api';

const assetsService = axiosWithHeaders({
	config,
	service: 'assets',
	axiosCreateOpts: {
		timeout: 15000,
	},
});

export default assetsService;
