import { Field } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { BOOLEAN_VALUES, FIELDS_TYPES } from '@Utils/choiceListMapping';
import { Select as MuSelect, TextField as MUITextField } from 'formik-mui';
import { FormControl, Grid, MenuItem } from '@mui/material';
import InputMultiSelect from '../UI/InputMultiSelect';
import InputDatePicker from '@Components/UI/InputDatePicker';
import { selectFieldOptions } from '@Selectors/ChoiceList.selectors';
import { t } from 'i18next';

export interface FormikInputValueOptions {
	dateFormat?: string;
	disableInput?: boolean;
}

export const booleanValues = [
	{
		label: BOOLEAN_VALUES.YES,
		value: BOOLEAN_VALUES.YES,
	},

	{
		label: BOOLEAN_VALUES.NO,
		value: BOOLEAN_VALUES.NO,
	},
];

const checkValueType = (
	valueType: string,
	fieldOptions: [],
	extras?: FormikInputValueOptions,
) => {
	switch (valueType) {
		case FIELDS_TYPES.BOOLEAN:
			return (
				<Field
					fullWidth
					disabled={!!extras?.disableInput}
					id="value1"
					component={MuSelect}
					label={t('Pick Select')}
					name="value1"
					variant="outlined">
					{booleanValues.map(({ label, value }) => (
						<MenuItem key={value} value={value}>
							{label}
						</MenuItem>
					))}
				</Field>
			);
		case FIELDS_TYPES.TEXT_ONE_FIELD:
			return (
				<Grid item xs={12}>
					<Field
						fullWidth
						disabled={!!extras?.disableInput}
						component={MUITextField}
						label={t('Value')}
						type="text"
						name="value1"
						variant="outlined"
					/>
				</Grid>
			);
		case FIELDS_TYPES.NUMERICAL_ONE_FIELD:
		case FIELDS_TYPES.NUMERICAL_UNRESTRICTED_ONE_FIELD:
		case FIELDS_TYPES.NUMERIC_CONDITION_SCORE_DEPRICATED:
		case FIELDS_TYPES.CURRENCY_ONE_FIELD:
			return (
				<Grid item xs={12}>
					<Field
						fullWidth
						disabled={!!extras?.disableInput}
						component={MUITextField}
						label={t('Value')}
						min={0}
						type="number"
						name="value1"
						variant="outlined"
					/>
				</Grid>
			);
		case FIELDS_TYPES.NUMERICAL_TWO_FIELDS:
		case FIELDS_TYPES.NUMERICAL_UNRESTRICTED_TWO_FIELDS:
		case FIELDS_TYPES.CURRENCY_TWO_FIELDS:
			return (
				<>
					<Grid item xs={12}>
						<Field
							fullWidth
							disabled={!!extras?.disableInput}
							component={MUITextField}
							label={t('Value 1')}
							type="number"
							name="value1"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							fullWidth
							disabled={!!extras?.disableInput}
							component={MUITextField}
							label={t('Value 2')}
							type="number"
							name="value2"
							variant="outlined"
						/>
					</Grid>
				</>
			);
		case FIELDS_TYPES.MULTI_SELECT_ONE_FIELD:
			return (
				<Grid item xs={12}>
					<FormControl variant="outlined" fullWidth>
						<Field
							disabled={!!extras?.disableInput}
							label={t('Values')}
							instanceId="MultiSelect2"
							name="value1"
							component={InputMultiSelect}
							placeholder={''}
							options={
								fieldOptions.length > 0
									? fieldOptions.map((option: any) => {
											return {
												value: option.value,
												label: option.label,
											};
									  })
									: []
							}></Field>
					</FormControl>
				</Grid>
			);
		case FIELDS_TYPES.DATE_ONE_FIELD:
			return (
				<Grid item xs={12}>
					<FormControl variant="outlined" fullWidth>
						<Field
							disabled={!!extras?.disableInput}
							label={t('Choose Date')}
							name="value1"
							cancelMinDate
							component={InputDatePicker}
							isRequired={true}
							dateFormat={extras?.dateFormat}
						/>
					</FormControl>
				</Grid>
			);
		case FIELDS_TYPES.DATE_TWO_FIELDS:
			return (
				<>
					<Grid item xs={12}>
						<FormControl variant="outlined" fullWidth>
							<Field
								disabled={!!extras?.disableInput}
								label={t('Choose Date (From)')}
								name="value1"
								cancelMinDate
								component={InputDatePicker}
								isRequired={true}
								dateFormat={extras?.dateFormat}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<FormControl variant="outlined" fullWidth>
							<Field
								disabled={!!extras?.disableInput}
								label={t('Choose Date (To)')}
								name="value2"
								cancelMinDate
								component={InputDatePicker}
								isRequired={true}
								dateFormat={extras?.dateFormat}
							/>
						</FormControl>
					</Grid>
				</>
			);
		case FIELDS_TYPES.DATE_YEAR_ONE_FIELD:
			return (
				<Grid item xs={12}>
					<Grid item xs={12}>
						<Field
							disabled={!!extras?.disableInput}
							fullWidth
							component={MUITextField}
							label={t('Value')}
							type="number"
							name="value1"
							variant="outlined"
						/>
					</Grid>{' '}
				</Grid>
			);
		case FIELDS_TYPES.DATE_YEAR_TWO_FIELDS:
			return (
				<>
					<Grid item xs={12}>
						<Field
							fullWidth
							disabled={!!extras?.disableInput}
							component={MUITextField}
							label={t('Year From')}
							type="number"
							name="value1"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							fullWidth
							disabled={!!extras?.disableInput}
							component={MUITextField}
							label={t('Year To')}
							type="number"
							name="value2"
							variant="outlined"
						/>
					</Grid>
				</>
			);
		case FIELDS_TYPES.DAYS_SINCE:
			return (
				<Grid item xs={12}>
					<Field
						fullWidth
						disabled={!!extras?.disableInput}
						component={MUITextField}
						label={t('Number of days passed')}
						min={0}
						type="number"
						name="value1"
						variant="outlined"
					/>
				</Grid>
			);
		case FIELDS_TYPES.YEARS_SINCE:
			return (
				<Grid item xs={12}>
					<Field
						fullWidth
						disabled={!!extras?.disableInput}
						component={MUITextField}
						label={t('Number of years passed')}
						min={0}
						type="number"
						name="value1"
						variant="outlined"
					/>
				</Grid>
			);
		case FIELDS_TYPES.PERCENTAGE_TEXT:
			return (
				<Grid item xs={12}>
					<Field
						fullWidth
						disabled={!!extras?.disableInput}
						component={MUITextField}
						label={t('Percent (%) of')}
						min={0}
						max={100}
						type="number"
						name="value1"
						variant="outlined"
					/>
				</Grid>
			);
		case FIELDS_TYPES.PERCENTAGE_TWO_FIELDS:
			return (
				<>
					<Grid item xs={12}>
						<Field
							fullWidth
							disabled={!!extras?.disableInput}
							component={MUITextField}
							label={t('Percentage 1(%)')}
							type="number"
							name="value1"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							fullWidth
							disabled={!!extras?.disableInput}
							component={MUITextField}
							label={t('Percentage 2(%)')}
							type="number"
							name="value2"
							variant="outlined"
						/>
					</Grid>
				</>
			);
		case FIELDS_TYPES.CLOCK_ONE_FIELD:
		case FIELDS_TYPES.CLOCK_EXTENT_ONE_FIELD:
			return (
				<Grid item xs={12}>
					<Field
						fullWidth
						disabled={!!extras?.disableInput}
						component={MUITextField}
						label={t('Value')}
						min={1}
						max={12}
						type="number"
						name="value1"
						variant="outlined"
					/>
				</Grid>
			);
		case FIELDS_TYPES.CLOCK_TWO_FIELDS:
			return (
				<>
					<Grid item xs={12}>
						<Field
							fullWidth
							disabled={!!extras?.disableInput}
							component={MUITextField}
							label={t('Clock Position 1')}
							min={1}
							max={12}
							type="number"
							name="value1"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							fullWidth
							disabled={!!extras?.disableInput}
							component={MUITextField}
							label={t('Clock Position 2')}
							min={1}
							max={12}
							type="number"
							name="value2"
							variant="outlined"
						/>
					</Grid>
				</>
			);
		case FIELDS_TYPES.CLOCK_EXTENT_TWO_FIELDS:
			return (
				<>
					<Grid item xs={12}>
						<Field
							fullWidth
							disabled={!!extras?.disableInput}
							component={MUITextField}
							label={t('Value 1')}
							min={1}
							max={12}
							type="number"
							name="value1"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							fullWidth
							disabled={!!extras?.disableInput}
							component={MUITextField}
							label={t('Value 2')}
							min={1}
							max={12}
							type="number"
							name="value2"
							variant="outlined"
						/>
					</Grid>
				</>
			);
		default:
			return '';
	}
};
//,formRef:React.RefObject<FormikProps<any>>
const useFormikInputValue: any = (
	valueTypeInit: string,
	options?: FormikInputValueOptions,
): any => {
	const [inputField, setInputField] = useState<ReactElement | string>(<></>);
	const [valueType, setValueType] = useState(valueTypeInit);
	//const [errors,setErrors] = useState<any>({});
	const fieldOptionsSelector = selectFieldOptions();
	const [extras, setExtras] = useState(options);

	useEffect(() => {
		if (!valueType) return;

		const formikField = checkValueType(
			valueType,
			fieldOptionsSelector,
			extras,
		);
		setInputField(formikField);
	}, [valueType, fieldOptionsSelector, extras]);

	return [inputField, setValueType, valueType, setExtras];
};

export default useFormikInputValue;
