import React, { ReactElement, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import {
	RehabRuleChoiceListFiles,
	ConfigObj,
	Rules,
	RULE_CONDITION,
	CompareOptions,
} from '@Types';
import { useStyles } from './RulesList.styles';
import {
	ChoiceListPairs,
	getAllOperatorsForSpecificField,
	getChoiceListFromSpecificFile,
	getValueTypeForSpecificOperator,
} from '@Utils/choiceListFilesUtils';
import {
	BOOLEAN_VALUES,
	FIELDS_TYPES,
	RECORD_TYPE,
} from '@Utils/choiceListMapping';
import { format, parseISO } from 'date-fns';
import { useUnits, useTzDateTime } from '@innovyze/stylovyze';
import SortableRuleList from './SortableList/SortableRuleList';
import { arrayMoveImmutable } from 'array-move';
import { TFunc, useGlobalization } from '@Translations';
import { formatDateWithFormatDate } from '@innovyze/lib-am-common/Utils';
import {
	selectAllRiskConfigsIds,
	selectAllTaskConfigsIds,
	selectAllEventConfigsIds,
	selectAllActionConfigsIds,
} from '@Selectors/ChoiceList.selectors';

export const getRuleLabel = (
	t: TFunc,
	recordType: string | undefined,
	label: string,
	value: string,
) => {
	if (recordType && recordType === RECORD_TYPE.RISK) {
		if (value.startsWith('LOFRaw_')) {
			return t(`LoF/${label}/Score`);
		} else if (value.startsWith('COFRaw_')) {
			return t(`CoF/${label}/Score`);
		} else if (value.startsWith('LOF_')) {
			return t(`LoF/${label}/Weighted Score`);
		} else if (value.startsWith('COF_')) {
			return t(`CoF/${label}/Weighted Score`);
		} else {
			return label;
		}
	} else {
		return label;
	}
};

export const getRuleFieldDisplay = (
	recordTypeChoiceListFiles: RehabRuleChoiceListFiles,
	recordType: string,
	config: string,
	field: string,
) => {
	let configObj = null;
	if (recordType === 'Risk') {
		const riskConfigs = selectAllRiskConfigsIds();
		configObj = riskConfigs?.find(
			(ele: ConfigObj) => ele.configId == config,
		);
	} else if (recordType === 'Task') {
		const taskConfigs = selectAllTaskConfigsIds();
		configObj = taskConfigs?.find(
			(ele: ConfigObj) => ele.configId == config,
		);
	} else if (recordType === 'Action') {
		const actionConfigs = selectAllActionConfigsIds();
		configObj = actionConfigs?.find(
			(ele: ConfigObj) => ele.configId == config,
		);
	}  else if (recordType == 'Event') {
		const eventConfigs = selectAllEventConfigsIds();
		configObj = eventConfigs?.find(
			(ele: ConfigObj) => ele.configId == config,
		);
	}

	let configName = '';
	if (!configObj) {
		if (config) {
			configName = config;
		}
	} else {
		configName = configObj.name;
	}

	let fieldAlias = field;
	const recordTypeFields: ChoiceListPairs[] = getChoiceListFromSpecificFile(
		recordTypeChoiceListFiles,
		recordType ?? '',
		config,
	);
	if (field && recordTypeFields) {
		const configField = recordTypeFields.find(f => f.value === field);
		if (configField) {
			fieldAlias = configField.label;
		}
	}

	const ruleFieldDisplay = configName
		? `[${configName}].${fieldAlias}`
		: fieldAlias;
	return ruleFieldDisplay;
};

export const showRuleValues = (
	recordTypeChoiceListFiles: RehabRuleChoiceListFiles,
	rule: Rules,
	classes: any,
	dragIndicator: ReactElement,
): any => {
	const { t } = useGlobalization();
	// Fix up DOSE_NOT_EQUALS
	let operator = rule.operator;
	if (operator) {
		operator = operator.replace('DOSE_NOT_EQUALS', 'DOES_NOT_EQUAL');
	}

	let valueInputType = '';
	if (
		(rule.compare as CompareOptions)?.recordType &&
		(rule.compare as CompareOptions)?.field
	) {
		const compareWithValue = ['DAYS_SINCE', 'YEARS_SINCE'];
		valueInputType =
			compareWithValue.includes(operator as string) ||
			rule.recordType === 'Action'
				? FIELDS_TYPES.COMPARE_WITH_VALUE
				: FIELDS_TYPES.COMPARE;
	} else if (rule.recordType && rule.field && operator) {
		valueInputType = getValueTypeForSpecificOperator(
			recordTypeChoiceListFiles,
			rule.recordType,
			rule.field,
			operator,
			rule.config,
		);
	}

	let operatorAlias = operator;
	const fieldOperators: ChoiceListPairs[] = getAllOperatorsForSpecificField(
		recordTypeChoiceListFiles,
		rule.recordType ?? '',
		rule.field ?? '',
		rule.config,
	);
	if (fieldOperators) {
		const field = fieldOperators.find(field => field.value === operator);
		if (field) {
			operatorAlias = field.label;
		}
	}

	const { getSystemValueFormatted } = useUnits();
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;

	const ruleFieldDisplay = getRuleFieldDisplay(
		recordTypeChoiceListFiles,
		rule.recordType as string,
		rule.config as string,
		rule.field as string,
	);

	switch (valueInputType) {
		case FIELDS_TYPES.BOOLEAN:
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<div>
						<span
							id="sortable-rule_text"
							className={classes.ruleType}>
							{ruleFieldDisplay}&nbsp;
						</span>
						<span className={classes.ruleOperator}>
							{rule.value1 &&
							(rule.value1 as Array<any>).length > 0 &&
							(rule.value1 as Array<any>)[0] ===
								BOOLEAN_VALUES.YES
								? t('IS ')
								: t('IS ')}
							:
						</span>
						<span className={classes.ruleValue}>
							{(rule.value1 as Array<any>)[0]}
						</span>
					</div>
				</div>
			);
		case FIELDS_TYPES.TEXT_ONE_FIELD:
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<div>
						<span
							id="sortable-rule_text"
							className={classes.ruleType}>
							{ruleFieldDisplay}&nbsp;
						</span>
						<span className={classes.ruleOperator}>
							{operatorAlias}:&nbsp;
						</span>
						<span className={classes.ruleValue}>
							{rule.value1 &&
								(rule.value1 as Array<any>).length > 0 &&
								(rule.value1 as Array<any>)[0]}
						</span>
					</div>
				</div>
			);
		case FIELDS_TYPES.NUMERIC_CONDITION_SCORE_DEPRICATED:
		case FIELDS_TYPES.PERCENTAGE_TEXT:
		case FIELDS_TYPES.NUMERICAL_ONE_FIELD:
		case FIELDS_TYPES.NUMERICAL_UNRESTRICTED_ONE_FIELD:
		case FIELDS_TYPES.CURRENCY_ONE_FIELD:
		case FIELDS_TYPES.CLOCK_ONE_FIELD:
		case FIELDS_TYPES.CLOCK_EXTENT_ONE_FIELD:
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<div>
						<span
							id="sortable-rule_text"
							className={classes.ruleType}>
							{ruleFieldDisplay}&nbsp;
						</span>
						<span className={classes.ruleOperator}>
							{operatorAlias}:&nbsp;
						</span>
						<span className={classes.ruleValue}>
							{rule.value1 &&
							(rule.value1 as Array<any>).length > 0
								? getSystemValueFormatted(
										` ${(rule.value1 as Array<any>)[0]} ${
											rule.unit
										}`,
								  )
								: null}
						</span>
					</div>
				</div>
			);
		case FIELDS_TYPES.PERCENTAGE_TWO_FIELDS:
		case FIELDS_TYPES.NUMERICAL_TWO_FIELDS:
		case FIELDS_TYPES.NUMERICAL_UNRESTRICTED_TWO_FIELDS:
		case FIELDS_TYPES.CURRENCY_TWO_FIELDS:
		case FIELDS_TYPES.CLOCK_TWO_FIELDS:
		case FIELDS_TYPES.CLOCK_EXTENT_TWO_FIELDS:
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<div>
						<span
							id="sortable-rule_text"
							className={classes.ruleType}>
							{ruleFieldDisplay}&nbsp;
						</span>
						<span className={classes.ruleOperator}>
							{operatorAlias}:&nbsp;
						</span>
						<span className={classes.ruleValue}>
							{rule.value1 &&
							(rule.value1 as Array<any>).length > 0
								? getSystemValueFormatted(
										` ${(rule.value1 as Array<any>)[0]} ${
											rule.unit
										}`,
								  )
								: null}
						</span>
						<span className={classes.ruleOperator}>
							{t(' AND ')}
						</span>
						<span className={classes.ruleValue}>
							{rule.value2
								? getSystemValueFormatted(
										` ${rule.value2} ${rule.unit}`,
								  )
								: null}
						</span>
					</div>
				</div>
			);
		case FIELDS_TYPES.MULTI_SELECT_ONE_FIELD:
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<div>
						<span
							id="sortable-rule_text"
							className={classes.ruleType}>
							{ruleFieldDisplay}&nbsp;
						</span>
						<span className={classes.ruleOperator}>
							{operatorAlias}:&nbsp;
						</span>
						<span className={classes.ruleValue}>
							{rule.value1 &&
								(rule.value1 as Array<any>).length > 0 &&
								(rule.value1 as Array<any>).join(',')}
						</span>
					</div>
				</div>
			);
		case FIELDS_TYPES.DATE_ONE_FIELD:
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<div>
						<span
							id="sortable-rule_text"
							className={classes.ruleType}>
							{ruleFieldDisplay}&nbsp;
						</span>
						<span className={classes.ruleOperator}>
							{operatorAlias}:&nbsp;
						</span>
						<span className={classes.ruleValue}>
							{rule.value1 &&
							(rule.value1 as Array<any>).length > 0
								? formatDateWithFormatDate(
										(
											rule.value1 as Array<any>
										)[0] as string,
										formatDateUTC,
								  )
								: ''}
						</span>
					</div>
				</div>
			);
		case FIELDS_TYPES.DATE_TWO_FIELDS:
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<div>
						<span
							id="sortable-rule_text"
							className={classes.ruleType}>
							{ruleFieldDisplay}&nbsp;
						</span>
						<span className={classes.ruleOperator}>
							{operatorAlias}:&nbsp;
						</span>
						<span className={classes.ruleValue}>
							{rule.value1 &&
							(rule.value1 as Array<any>).length > 0
								? formatDateWithFormatDate(
										(
											rule.value1 as Array<any>
										)[0] as string,
										formatDateUTC,
								  )
								: ''}
						</span>
						<span className={classes.ruleOperator}>
							{t(' AND ')}
						</span>
						<span className={classes.ruleValue}>
							{rule.value2
								? formatDateWithFormatDate(
										(Array.isArray(rule.value2)
											? (rule.value2 as Array<any>)[0]
											: rule.value2) as string,
										formatDateUTC,
								  )
								: ''}
						</span>
					</div>
				</div>
			);
		case FIELDS_TYPES.DATE_YEAR_ONE_FIELD:
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<div>
						<span
							id="sortable-rule_text"
							className={classes.ruleType}>
							{ruleFieldDisplay}&nbsp;
						</span>
						<span className={classes.ruleOperator}>
							{operatorAlias}:&nbsp;
						</span>
						<span className={classes.ruleValue}>
							{rule.value1 &&
							(rule.value1 as Array<any>).length > 0
								? format(
										new Date(
											parseISO(
												(rule.value1 as Array<any>)[0],
											),
										),
										'yyyy',
								  )
								: ''}
						</span>
					</div>
				</div>
			);
		case FIELDS_TYPES.DATE_YEAR_TWO_FIELDS:
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<div>
						<span
							id="sortable-rule_text"
							className={classes.ruleType}>
							{ruleFieldDisplay}&nbsp;
						</span>
						<span className={classes.ruleOperator}>
							{operatorAlias}:&nbsp;
						</span>
						<span className={classes.ruleValue}>
							{rule.value1 &&
							(rule.value1 as Array<any>).length > 0
								? format(
										new Date(
											parseISO(
												(rule.value1 as Array<any>)[0],
											),
										),
										'yyyy',
								  )
								: ''}
						</span>
						<span className={classes.ruleOperator}>{' AND '}</span>
						<span className={classes.ruleValue}>
							{rule.value2
								? format(
										new Date(
											parseISO(rule.value2 as string),
										),
										'yyyy',
								  )
								: ''}
						</span>
					</div>
				</div>
			);
		case FIELDS_TYPES.DAYS_SINCE:
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<div>
						<span
							id="sortable-rule_text"
							className={classes.ruleType}>
							{ruleFieldDisplay}&nbsp;
						</span>
						<span className={classes.ruleOperator}>Since:</span>
						<span className={classes.ruleValue}>
							{rule.value1 &&
								(rule.value1 as Array<any>).length > 0 &&
								(rule.value1 as Array<any>)[0]}
						</span>
						<span className={classes.ruleOperator}>
							{t('day(s)')}
						</span>
					</div>
				</div>
			);
		case FIELDS_TYPES.YEARS_SINCE:
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<div>
						<span
							id="sortable-rule_text"
							className={classes.ruleType}>
							{ruleFieldDisplay}&nbsp;
						</span>
						<span className={classes.ruleOperator}>Since:</span>
						<span className={classes.ruleValue}>
							{rule.value1 &&
								(rule.value1 as Array<any>).length > 0 &&
								(rule.value1 as Array<any>)[0]}
						</span>
						<span className={classes.ruleOperator}>
							{t('year(s)')}
						</span>
					</div>
				</div>
			);
		case FIELDS_TYPES.COMPARE:
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<div>
						<span
							id="sortable-rule_text"
							className={classes.ruleType}>
							{ruleFieldDisplay}&nbsp;
						</span>
						<span className={classes.ruleOperator}>
							{operatorAlias}:&nbsp;
						</span>
						<span className={classes.ruleType}>
							{getRuleFieldDisplay(
								recordTypeChoiceListFiles,
								(rule.compare as CompareOptions)?.recordType,
								(rule.compare as CompareOptions)
									?.config as string,
								(rule.compare as CompareOptions)?.field,
							)}
						</span>
					</div>
				</div>
			);
		case FIELDS_TYPES.COMPARE_WITH_VALUE: {
			let unit = '%';
			if (operator == 'YEARS_SINCE') {
				unit = t('year(s)');
			} else if (operator == 'DAYS_SINCE') {
				unit = t('day(s)');
			}
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<div>
						<span
							id="sortable-rule_text"
							className={classes.ruleType}>
							{ruleFieldDisplay}&nbsp;
						</span>
						<span className={classes.ruleOperator}>
							{operatorAlias}:&nbsp;
						</span>
						<span className={classes.ruleValue}>{rule.value1}</span>
						<span className={classes.ruleOperator}>{unit}</span>
						<span className={classes.ruleType}>
							{getRuleFieldDisplay(
								recordTypeChoiceListFiles,
								(rule.compare as CompareOptions)?.recordType,
								(rule.compare as CompareOptions)
									?.config as string,
								(rule.compare as CompareOptions)?.field,
							)}
						</span>
					</div>
				</div>
			);
		}
		default:
			return (
				<div className={classes.ruleLine}>
					{dragIndicator}
					<span id="sortable-rule_text" className={classes.ruleType}>
						{ruleFieldDisplay}
					</span>
				</div>
			);
	}
};

interface RulesListProps {
	setRules: (rules: Rules[]) => void;
	rules: Rules[];
	onAddRule: () => void;
	onEditRule: (id: string, groupedId: string | null) => void;
	onDeleteRule: (id: string, groupedId: string | null) => void;
	onAddRuleCondition: (condition: string, groupedRuleId: string) => void;
	setQueryPanelDisabledState: (state: boolean) => void;
	isQueryPanelDisabled: boolean;
	isFilter: boolean;
}
export const RulesList = (props: RulesListProps) => {
	const { t } = useGlobalization();
	const classes = useStyles();
	//const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
	const {
		rules,
		onAddRule,
		onEditRule,
		onDeleteRule,
		onAddRuleCondition,
		setRules,
		setQueryPanelDisabledState,
		isQueryPanelDisabled,
		isFilter,
	} = props;
	const [showRuleCondition, setShowRuleCondition] = useState(false);
	const [ruleIdToCreateGrouped, setRuleIdToCreateGrouped] = useState('');

	const handleConditionClicked = (condition: string) => {
		onAddRuleCondition(condition, '');
		setShowRuleCondition(false);
		setRuleIdToCreateGrouped('');
		onAddRule();
		setQueryPanelDisabledState(true);
	};

	const handleConditionClickedForGroupedRule = (
		condition: string,
		ruleId: string,
	) => {
		onAddRuleCondition(condition, ruleId);
		setShowRuleCondition(false);
		setRuleIdToCreateGrouped('');
		onAddRule();
		setQueryPanelDisabledState(true);
	};

	const updateRuleCondition = (rule: Rules) => {
		let newRule = rule;
		newRule = {
			...newRule,
			ruleCondition:
				newRule.ruleCondition === RULE_CONDITION.AND
					? RULE_CONDITION.OR
					: RULE_CONDITION.AND,
		};
		const updatedRules = rules.map(ruleEle => {
			const updatedRule: Rules =
				ruleEle.id === newRule.id
					? {
							...ruleEle,
							...newRule,
					  }
					: ruleEle;
			return updatedRule;
		});
		setRules(updatedRules);
	};

	const updateGroupRuleCondition = (rule: Rules, groupedRule: Rules) => {
		const updatedRules = rules.map(ruleEle => {
			const updatedRule: Rules =
				ruleEle.id === rule.id
					? {
							...ruleEle,
							groupedRules: ruleEle.groupedRules?.map(
								groupedRuleEle => {
									const newGroupedRules =
										groupedRuleEle.id === groupedRule.id
											? {
													...groupedRuleEle,
													ruleCondition:
														groupedRuleEle.ruleCondition ===
														RULE_CONDITION.AND
															? RULE_CONDITION.OR
															: RULE_CONDITION.AND,
											  }
											: groupedRuleEle;

									return newGroupedRules;
								},
							),
					  }
					: ruleEle;
			return updatedRule;
		});
		setRules(updatedRules);
	};

	const onSortRuleEnd = ({ oldIndex, newIndex }: any) => {
		const newRules = arrayMoveImmutable(rules, oldIndex, newIndex);

		const firstEleIndex = newRules.indexOf(rules[oldIndex]);
		let secondEleIndex = newRules.indexOf(rules[newIndex]);
		if (
			(firstEleIndex || firstEleIndex === 0) &&
			(secondEleIndex || secondEleIndex === 0)
		) {
			if (oldIndex == 0 && newIndex - oldIndex > 1) {
				secondEleIndex = 0;
			}
			const swapRuleCondition = newRules[firstEleIndex].ruleCondition
				? newRules[firstEleIndex].ruleCondition
				: '';
			newRules[firstEleIndex] = {
				...newRules[firstEleIndex],
				ruleCondition: newRules[secondEleIndex].ruleCondition
					? newRules[secondEleIndex].ruleCondition
					: '',
			};
			newRules[secondEleIndex] = {
				...newRules[secondEleIndex],
				ruleCondition: swapRuleCondition,
			};
			setRules([...newRules]);
		}
	};
	function shouldCancelStart(e: any) {
		// Cancel sorting if the event target is an `input`, `textarea`, `select` or `option`
		const elementId: string = e.target.id;
		const elementClass: string = e.target.classList[0];

		if (
			(elementId && elementId.includes('sortable-rule_icon')) ||
			(elementClass && elementClass.includes('ruleDragIcon'))
		) {
			return false; // Return true to cancel sorting
		}
		return true;
	}

	return (
		<>
			<SortableRuleList
				// useDragHandle
				// lockAxis="y"
				//pressDelay={100}
				lockAxis="y"
				lockToContainerEdges
				rules={rules}
				classes={classes}
				ruleIdToCreateGrouped={ruleIdToCreateGrouped}
				updateRuleCondition={updateRuleCondition}
				updateGroupRuleCondition={updateGroupRuleCondition}
				setRuleIdToCreateGrouped={setRuleIdToCreateGrouped}
				setShowRuleCondition={setShowRuleCondition}
				onEditRule={onEditRule}
				onDeleteRule={onDeleteRule}
				handleConditionClickedForGroupedRule={
					handleConditionClickedForGroupedRule
				}
				onSortEnd={onSortRuleEnd}
				shouldCancelStart={shouldCancelStart}
				setRules={setRules}
				setQueryPanelDisabledState={setQueryPanelDisabledState}
				isQueryPanelDisabled={isQueryPanelDisabled}
				isFilter={isFilter}
			/>
			<Grid
				container
				className={classes.addRuleGrid}
				alignItems="center"
				spacing={1}>
				<Grid item xs={4}>
					<Button
						className={classes.addRuleBtn}
						variant="outlined"
						startIcon={<Add />}
						disabled={isQueryPanelDisabled}
						onClick={() => {
							setRuleIdToCreateGrouped('');
							setShowRuleCondition(!showRuleCondition);
						}}>
						{t(isFilter ? 'Add Filter' : 'Add Rule')}
					</Button>
				</Grid>
				{showRuleCondition && !isQueryPanelDisabled && (
					<Grid item xs={4}>
						<Button
							className={classes.ruleConditionAnd}
							variant="outlined"
							onClick={() => {
								handleConditionClicked(RULE_CONDITION.AND);
							}}>
							{t('AND')}
						</Button>
						<Button
							className={classes.ruleConditionOr}
							variant="outlined"
							onClick={() => {
								handleConditionClicked(RULE_CONDITION.OR);
							}}>
							{t('OR')}
						</Button>
					</Grid>
				)}
			</Grid>
		</>
	);
};
export default RulesList;
