import { axiosWithHeaders, getService } from '@innovyze/lib_get_service';
import config from './config.api';
import { getConfigUrl, getEnvironment } from '@innovyze/stylovyze';
import * as LDClient from 'launchdarkly-js-client-sdk';

const rehabService = axiosWithHeaders({
	service: 'amRehab',
	config,
	axiosCreateOpts: { timeout: 30000 },
});

const cloudOsRehabBackendBaseUrl = getService('saasAmRehab', config);

class LaunchDarklyClientSingleton {
	private instance: LaunchDarklyClientSingleton | null = null;
	private ldClient: LDClient.LDClient | null = null;

	constructor() {
		if (this.instance) {
			return this.instance;
		}

		this.instance = this;
	}

	private async getSiteCfg() {
		const env = getEnvironment();
		const configUrl = getConfigUrl(env);

		const siteCfg = await fetch(configUrl, {
			headers: {
				'Cache-Control': 'no-cache',
				Pragma: 'no-cache',
				Expires: '0',
			},
		});

		const siteCfgJson = await siteCfg.json();

		return siteCfgJson;
	}

	public async getClient() {
		if (!this.ldClient) {
			const siteCfg = await this.getSiteCfg();
			const clientId = siteCfg.launchDarkly.clientSideId;

			const client = LDClient.initialize(clientId, {
				key: 'temp-user-key',
			});

			await client.waitForInitialization(10);
			this.ldClient = client;
		}

		return this.ldClient;
	}
}

const ldClientSingleton = new LaunchDarklyClientSingleton();


rehabService.interceptors.request.use(async cfg => {
	const ldClient = await ldClientSingleton.getClient();

	const useCloudOS = ldClient.variation('use-saas-am-rehab-backend', false);
	if (useCloudOS) {
		return {
			...cfg,
			baseURL: cloudOsRehabBackendBaseUrl,
		};
	}

	return cfg;
});

export default rehabService;
